import { Button, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { ShareIcon } from '../../../Assets/icons';
import { SIDE_PANEL_INFO } from '../../../Constants/scholarship';
import useMixpanel from '../../../lib/Analytics/Mixpanel/useMixpanel';
import StyledCard from '../../../lib/UI-Components/StyledCards';
import { IScholarship } from '../../../types/scholarships';
import InfoItem from './InfoItem';

interface SidePanelProps {
  data: IScholarship;
}

const SidePanel: React.FC<SidePanelProps> = ({ data }) => {
  const { trackEvent } = useMixpanel();

  return (
    <StyledCard>
      <VStack width={'100%'} alignItems={'flex-start'} justifyContent={'flex-start'} spacing={6}>
        {SIDE_PANEL_INFO.map((info) => {
          const value = data[info.key];

          if (info.visible(value)) {
            const formattedValue = info.format ? info.format(value) : value;

            return <InfoItem key={info.key} heading={info.heading} subheading={formattedValue} Icon={info.Icon} />;
          }

          return null;
        })}
        {data?.link && (
          <Button
            width={'100%'}
            rightIcon={<ShareIcon color='foreground' boxSize={'14px'} />}
            variant={'primaryWithoutShadow'}
            onClick={() => {
              trackEvent('Apply Now -  Scholarship');
              window.open(data.link, '_blank');
            }}
          >
            <Text color='foreground' fontSize='sm' fontWeight={400} lineHeight='140%'>
              Apply now
            </Text>
          </Button>
        )}
      </VStack>
    </StyledCard>
  );
};

export default SidePanel;
