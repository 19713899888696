import { useQuery } from '@tanstack/react-query';
import scholarshipService from 'Services/scholarships';

const useGetAllScholarships = (page: number, perPage: number, filters: any) =>
  useQuery({
    queryKey: ['scholarships', page, perPage, filters],
    queryFn: () => scholarshipService.getScholarships({ page, perPage, ...filters }),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

const useGetScholarship = (id?: number) =>
  useQuery({
    queryKey: ['scholarship', id],
    queryFn: () => scholarshipService.getScholarshipById(id),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: !!id,
  });

export { useGetAllScholarships, useGetScholarship };
