import { extendTheme } from '@chakra-ui/react';
// @ts-expect-error
import { switchTheme } from './Components/Colleges/Theme/switchTheme.tsx';
// @ts-expect-error
import { checkboxTheme } from './Components/Colleges/Theme/checkboxTheme.tsx';
// @ts-expect-error
import { radioTheme } from './Components/Colleges/Theme/radioTheme.tsx';
// @ts-ignore

import { textAreaTheme } from './Components/Colleges/Theme/textAreaTheme.tsx';

const theme = {
  colors: {
    primary: '#035A41',
    primaryBlue: '#0E0E2C',
    primaryYellow: '#FFAC1C',
    secondaryBlue: '#4A4A68',
    secondaryBlueLight: '#4A4A681A',
    primaryDark: '#0C391D',
    primaryLight: '#3E8A75',
    secondaryGreen: '#0F393B',
    secondary: '#046873',
    secondaryDark: '#01405A',
    territary: '#8C8CA1',
    accent: '#046873',
    contrast: '#035A41',
    background: '#F7F7F8',
    foreground: '#FFFFFF',
    success: '#EEFEF4',
    successSecondary: '#40BC9E',
    navy: '#1e2435',
    offNavy: '#1e2335',
    brown: '#ab4d07',
    lightNavy: '#5c6fa3',
    white: '#ffffff',
    gray1: '#F9FAFB',
    gray2: '#D0D4DB',
    gray2Alpha: '#D0D4DB4A',
    gray3: '#98A2B3',
    gray4: '#818CA4',
    gray5: '#667085',
    gray6: '#49505F',
    gray7: '#EDF2F7',
    gray8: '#475467',
    gray9: '#EFF1F3',
    gray10: '#344054',
    gray11: '#E6E6E6',
    gray12: '#CDD8DF',
    gray13: '#8C8CA1',
    gray14: '#ECF1F4',
    gray15: '#E5E5EF',
    gray16: '#615E83',
    gray17: '#4A4A68',
    gray18: '#E2E8F0',
    gray19: '#FAFAFA',
    gray20: '#737373',
    slateGray: '#413F3A',
    lightGray: '#41414117',
    lighterCream: '#FFF0C2',
    backgroundDark: '#e6ddc5',
    lightBlue: '#FB9B50',
    lightBrown: '#BA9C7F',
    darkBlue: '#1D2939',
    target: '#FFF3E5',
    reach: '#F7DCFF',
    safety: '#E8FFF3',
    error: '#F97066',
    yellow: '#FBBF24',
    black1: '#0E0E2C',
    darkPurple: '#4A4A68',

    aiSuggestionsBG: '#F9F0FF',
    aiSuggestionsBorder: '#CE90FF',

    targetDark: '#F5A615',
    reachDark: '#8C489F',
    safetyDark: '#00A651',

    hoursPerWeek: '#8833FF40',

    grade9: '#24C29140',
    grade10: '#4BD4FF40',
    grade11: '#FF9A6C40',
    grade12: '#FFCB3340',

    red1: '#F97066',
    red2: '#FD6900',
    red3: '#FF4646',
    red4: '#FF6565',
    red5: '#FFEAEA',
    lightRed: '#FEF3F2',

    slategreen: '#3E8A75',
    slategreen50opacity: '#3E8A7588',
    lightGreen: '#ECF3F1',
    lightGreen1: '#F3FDFA',
    lightGreen2_006opacity: '#13d69c0f',
    lightGreen3: '#CFDED6EB',
    lightGreen4: '#81ACA0',
    green1: '#F2F4F7',
    green2: '#24C2911F',
    green3: '#428371',
    green4: '#C0D6CF',
    green5: '#F9FFFF',
    green6: '#F3FAF7',
    green7: '#EAF6ED',
    primaryGreen: '#3E8A75',
    darkGreen: '#024D37',
    darkGreen2: '#023D2D',
    successGreen: '#EEF8F6',

    danger: '#FFF7E8',
    danger2: '#FFAC1C',

    yellow1: '#FBBF24',

    purple: '#8900F7',
    lilac: '#F8EFFF',

    earlyAction: '#E8FFF3',
    earlyDecision: '#FFF3E5',
    regularDecision: '#FFEEE5',

    earlyActionDark: '#00A651',
    earlyDecisionDark: '#F5A615',
    regularDecisionDark: '#F56615',

    calendarC1: '#FB30C2',
    calendarC2: '#33BFFF',
    calendarC3: '#F9633D',
    calendarC4: '#953F22',
    calendarC5: '#8833FF',
    calendarC6: '#FFCB33',
    calendarC7: '#CC7429',
    calendarC8: '#0B8E69',

    activityAcademic: '#AD1F85',
    activitySTEM: '#2686B2',
    activityMisc: '#5D23B0',
    activitySports: '#086249',
    activityCommunication: '#A1801F',
    activityService: '#1D1D1D',
    activityArts: '#C14728',

    activityAcademicLight: '#FB30C240',
    activitySTEMLight: '#33BFFF40',
    activityMiscLight: '#8833FF40',
    activitySportsLight: '#0B8E6940',
    activityCommunicationLight: '#FFCB3340',
    activityServiceLight: '#1D1D1D4F',
    activityArtsLight: '#F9633D40',
  },
  shadows: {
    purple: '0 0 0 3px rgba(159, 122, 234, 0.6)',
  },
  styles: {
    global: {
      '@media (min-width: 1900px) and (min-height: 1000px)': {
        // Override default font sizes for larger screens
        html: {
          fontSize: 'xl',
        },
        body: {
          fontSize: 'md',
        },
        h1: {
          fontSize: 'xl',
        },
        h2: {
          fontSize: 'lg',
        },
      },
      // Apply custom scrollbar to all elements
      '*::-webkit-scrollbar': {
        width: '8px',
        borderRadius: '8px',
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: '#c4c4c4',
        borderRadius: '8px',
      },
      '*::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#a0a0a0',
      },
      body: {
        margin: 0,
        bg: 'background',
        color: 'navy',
        overflowX: 'hidden',
      },
    },
  },
  fonts: {
    heading: "'Montserrat', sans-serif",
    body: "'Montserrat', sans-serif",
  },
  components: {
    Tooltip: {
      baseStyle: {
        backgroundColor: 'gray6',
        boxShadow: '0px 0px 4px 0px rgba(73, 80, 95, 0.25)',
        borderRadius: '3xl',
        color: 'white',
        textAlign: 'center',
        paddingX: 3,
        paddingY: 2,
      },
      defaultProps: {
        trigger: 'hover',
      },
    },
    Tabs: {
      variants: {
        custom: {
          tab: {
            _selected: {
              color: 'primary',
              bg: 'white',
              fontWeight: 500,
              p: [1, 3, 5],
              borderBottom: '2px solid #035A41',
            },
            _hover: {
              bg: 'gray1',
            },
          },
          tablist: {
            borderBottom: '1px solid #CDD8DF',
          },
        },
        enclosed: {
          tab: {
            backgroundColor: 'gray2Alpha',
          },
        },
      },
    },
    Modal: {
      variants: {
        responsive: {
          // make it responsive
          dialog: {
            maxWidth: 'calc(80vh)',
          },
          contentContainer: {
            height: 'calc(80vh)',
          },
        },
      },
    },
    Slider: {
      baseStyle: {
        filledTrack: {
          bg: 'primaryDark',
        },
      },
    },
    IconButton: {
      variants: {
        college: {
          _hover: {
            bg: 'accent',
            color: 'foreground',
          },
        },
        ghost: {
          bg: 'white',
          color: 'primary',
          borderRadius: 'xl',
          border: '1px solid',
          borderColor: 'primary',
          boxShadow: '0px 4px 24px -16px rgba(105, 108, 112, 0.50)',
          _hover: {
            borderColor: 'primaryLight',
            bg: 'gray1',
          },
        },
      },
    },
    Popover: {
      variants: {
        picker: {
          popper: {
            maxWidth: 'unset',
            width: 'unset',
          },
        },
      },
    },
    Button: {
      variants: {
        basic: {
          bg: 'navy',
          color: 'background',
          _hover: {
            bg: 'accent',
            color: 'background',
          },
          _disabled: {
            _hover: {
              color: 'secondaryDark',
            },
          },
        },
        important: {
          bg: 'secondaryDark',
          color: 'foreground',
          _hover: {
            bg: 'accent',
            color: 'background',
          },
          _disabled: {
            _hover: {
              color: 'secondaryDark',
            },
          },
        },
        secondary: {
          bg: 'rgba(62, 138, 117, 0.16)',
          color: 'primaryLight',
          borderRadius: 'xl',
          _hover: {
            backgroundColor: 'rgba(3, 90, 65, 0.08)',
          },
        },
        primary_new: {
          bg: 'contrast',
          color: 'foreground',
          borderRadius: 'xl',
          textTransform: 'capitalize',
          _hover: {
            bg: 'accent',
            color: 'foreground',
          },
          _active: {
            bg: 'accent',
            color: 'foreground',
          },
          _disabled: {
            _hover: {
              color: 'secondaryDark',
            },
          },
        },
        primary: {
          borderRadius: 'xl',
          bg: 'primary',
          fontWeight: 'semibold',
          color: 'foreground',
          _hover: {
            bg: 'primaryLight',
          },
        },
        primaryWithoutShadow: {
          borderRadius: 'xl',
          bg: 'primary',
          color: 'foreground',
          _hover: {
            bg: 'primaryLight',
          },
        },
        lightRed: {
          borderRadius: 'xl',
          bg: 'lightRed',
          color: 'error',
          _hover: {
            bg: 'lightRed',
          },
          border: '1px solid',
        },
        primaryLight: {
          backgroundColor: 'primaryLight',
          borderRadius: 'xl',
          color: 'foreground',
          _hover: {
            bg: 'primary',
          },
        },
        ghost: {
          bg: 'white',
          color: 'primary',
          fontWeight: 'semibold',
          borderRadius: 'xl',
          border: '1px solid',
          borderColor: 'primary',
          boxShadow: '0px 4px 24px -16px rgba(105, 108, 112, 0.50)',
          _hover: {
            borderColor: 'primaryLight',
            bg: 'white',
          },
        },
        ghostSecondary: {
          paddingY: '12px',
          paddingX: '24px',
          borderRadius: '8px',
          borderWidth: '1px',
          borderColor: 'primary',
          bg: 'white',
          textColor: 'primary',
          fontSize: '16px',
          fontWeight: '600',
          _hover: { bg: 'white', textColor: 'primary' },
        },
        activityPrimary: {
          paddingY: '12px',
          paddingX: '24px',
          borderRadius: '8px',
          bg: 'primary',
          textColor: 'white',
          fontSize: '16px',
          fontWeight: '600',
        },
        activityAccept: {
          _hover: { bg: 'transparent', color: '#4A4A68' },
          bg: 'transparent',
          textColor: ['#035A41', '#035A41', '#035A41', '#4A4A68', '#4A4A68'],
          fontSize: ['16px', '16px', '14px', '14px', '14px'],
          fontWeight: ['600', '600', '600', '500', '500'],
          lineHeight: '19.6px',
          width: ['full', 'full', 'full', 'auto', 'auto'],
          textDecor: ['underline', 'underline', 'underline', 'none', 'none'],
        },
        activityReject: {
          _hover: { bg: 'transparent', color: '#4A4A68' },
          bg: 'transparent',
          textColor: ['#035A41', '#035A41', '#035A41', '#4A4A68', '#4A4A68'],
          fontSize: ['16px', '16px', '14px', '14px', '14px'],
          fontWeight: ['600', '600', '600', '500', '500'],
          lineHeight: '19.6px',
          width: ['full', 'full', 'full', 'auto', 'auto'],
          textDecor: ['underline', 'underline', 'underline', 'none', 'none'],
        },
        slateGreen: {
          bg: 'white',
          color: 'slategreen',
          borderRadius: 'xl',
          border: '1px solid',
          borderColor: 'slategreen',
          boxShadow: '0px 4px 24px -16px rgba(105, 108, 112, 0.50)',
          _hover: {
            borderColor: 'primaryLight',
            bg: 'gray1',
          },
        },
        error: {
          bg: 'error',
          borderRadius: 'xl',
          color: 'foreground',
          _hover: {
            bg: '#F0685E',
          },
        },
        logOut: {
          border: '1px solid',
          borderRadius: 'xl',
          borderColor: 'error',
          color: 'error',
          backgroundColor: 'lightRed',
          _hover: {
            background: '#FFE6E4',
          },
        },
        college: {
          bg: 'foreground',
          color: 'contrast',
          _hover: {
            bg: 'accent',
            color: 'foreground',
          },
        },
        aiSuggestion: {
          bg: 'rgba(136, 51, 255, 0.16)',
          borderColor: 'calendarC5',
          borderWidth: 1,
          borderStyle: 'solid',
          color: 'calendarC5',
          borderRadius: 'xl',
          fontSize: 'xs',
          textTransform: 'uppercase',
          _hover: {
            bg: 'calendarC5',
            color: 'foreground',
          },
        },
        first_ux: {
          p: '12px 24px',
          borderRadius: '12px',
          border: '1px solid white',
          bg: 'white',
          boxShadow: '0px 0px 12px 0px rgba(115, 115, 115, 0.05)',
          _hover: { bg: 'gray1', boxShadow: '0px 0px 12px 0px rgba(115, 115, 115, 0.25)' },
          _active: { bg: 'lightGreen1', borderColor: 'primaryLight' },
          color: 'primaryLight',
          fontWeight: '600',
        },
        recommendations: {
          bg: 'aiSuggestionsBorder',
          color: 'white',
          borderRadius: '8px',
          fontSize: '14px',
          fontWeight: 500,
          gap: 2,
          role: 'group',
          _hover: { bg: 'aiSuggestionsBorder' },
          _disabled: { bg: 'aiSuggestionsBorder', opacity: 0.5, color: 'white' },
        },
      },

      defaultProps: {
        size: 'md',
        variant: 'primaryWithoutShadow',
      },
    },
    Divider: {
      variants: {
        basic: {
          borderWidth: '3px',
          borderStyle: 'solid',
          opacity: '1',
          borderColor: 'navy',
        },
      },
      defaultProps: {
        size: 'md',
        variant: 'basic',
      },
    },
    Switch: switchTheme,
    Checkbox: checkboxTheme,
    Radio: radioTheme,

    TextArea: textAreaTheme,

    Progress: {
      baseStyle: {
        filledTrack: {
          bg: '#2fc0f9',
        },
      },
      variants: {
        green: {
          filledTrack: {
            bg: 'primaryLight',
          },
        },
        red: {
          filledTrack: {
            bg: 'red2',
          },
        },
        yellow: {
          filledTrack: {
            bg: 'yellow1',
          },
        },
        blueStyle: {
          filledTrack: {
            bg: 'calendarC2',
          },
        },
        redStyle: {
          filledTrack: {
            bg: 'calendarC1',
          },
        },
        yellowStyle: {
          filledTrack: {
            bg: 'calendarC6',
          },
        },
        emptyStyle: {
          filledTrack: {
            bg: 'background',
          },
        },
        accentStyle: {
          filledTrack: {
            bg: 'accent',
          },
        },
        defaultStyle: {
          filledTrack: {
            bg: 'primaryLight',
          },
        },
      },
    },
  },
};

export default extendTheme(theme);
