import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Text } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const BreadcrumbNav = ({ items, onNavigate }: any) => {
  const navigate = useNavigate();

  const handleNavigation = (href) => {
    if (onNavigate) {
      // If a callback is provided, use it
      onNavigate(href);
    } else {
      // Default behavior: navigate directly
      navigate(href);
    }
  };

  return (
    <Breadcrumb
      spacing={3}
      separator={
        <Text color='secondaryBlue' fontSize='xs'>
          /
        </Text>
      }
    >
      {items?.map((item, index) => (
        <BreadcrumbItem key={index} isCurrentPage={index === items?.length - 1}>
          {item?.href ? (
            <BreadcrumbLink onClick={() => handleNavigation(item?.href)}>
              <Text
                color={index === items?.length - 1 ? 'secondaryBlue' : 'gray13'}
                fontSize='xs'
                fontWeight={index === items?.length - 1 ? 700 : 400}
              >
                {item?.label}
              </Text>
            </BreadcrumbLink>
          ) : (
            <Text color='secondaryBlue' fontSize='xs' noOfLines={1}>
              {item?.label}
            </Text>
          )}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default BreadcrumbNav;
