import { Stack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { IScholarship } from '../../../types/scholarships';
import { OVERVIEW_INFO } from './../../../Constants/scholarship';
import InfoItem from './InfoItem';

interface IOverwiewProps {
  data: IScholarship;
}

const Overview: React.FC<IOverwiewProps> = ({ data }) => (
  <VStack width={'100%'} align='start' spacing={10} paddingY={3}>
    <Stack flexDirection={['column', 'column', 'row']} width={'100%'} spacing={10}>
      {OVERVIEW_INFO.map((info) => {
        const value = data[info.key];

        if (info.visible(value)) {
          const formattedValue = info.format ? info.format(value) : value;

          return <InfoItem key={info.key} heading={info.heading} subheading={formattedValue} Icon={info.Icon} />;
        }

        return null;
      })}
    </Stack>
    {!!data?.about && (
      <VStack width={'100%'} spacing={6} alignItems={'flex-start'}>
        <Text
          fontSize={['16px', '18px', '20px']}
          fontStyle='normal'
          fontWeight={600}
          lineHeight='normal'
          color='black1'
        >
          Description
        </Text>
        <Text fontSize={['12px', '14px', '16px']} fontStyle='normal' fontWeight={500} lineHeight='150%' color='gray13'>
          {data?.about}
        </Text>
      </VStack>
    )}
  </VStack>
);

export default Overview;
