import { Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';
import { UserIcon } from '../../Assets/icons';

interface ProfileAvatarProps extends FlexProps {
  currentColor: string;
}

const ProfileAvatar = ({ currentColor, ...props }: ProfileAvatarProps) => (
  <Flex
    rounded='full'
    cursor='pointer'
    bg={currentColor === 'gray17' ? 'gray14' : 'primary'}
    alignItems='center'
    justifyContent='center'
    color='foreground'
    textDecoration='none'
    fontWeight='semibold'
    {...props}
  >
    <UserIcon color={currentColor} />
  </Flex>
);

export default ProfileAvatar;
