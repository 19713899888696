import { Box, GridItem, SimpleGrid } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { setHighlight } from '../../Atoms/App/app.ts';
import { PrivateRoutes, PublicRoutes, Routes } from '../../Constants/constants';
import { mapPathToRoute } from '../../Utils/app';
import LinksSection from './Sections/LinksSection';
import LogoSection from './Sections/LogoSection';
import ProfileAndAddonsSection from './Sections/ProfileAndAddonsSection';

const Header = () => {
  const highlight = useAtomValue(setHighlight);
  const [location, setLocation] = useState('Home');
  const userLocation = useLocation();

  const isUserOnPublicRoute =
    PublicRoutes.includes(mapPathToRoute(userLocation?.pathname) as Routes) ||
    userLocation?.pathname.startsWith(Routes.Reset) ||
    userLocation?.pathname.startsWith(Routes.Verify);

  const isPrivateRoute = PrivateRoutes.includes(mapPathToRoute(userLocation?.pathname) as Routes);
  const path = useLocation();

  useEffect(() => {
    const url = path.pathname;
    const transformedPath = url.charAt(1).toUpperCase() + url.slice(2);
    url === Routes.GeneratePlan && setLocation('Plan');
    url === Routes.Profile ? setLocation('') : setLocation(transformedPath);
  }, [path.pathname, highlight]);

  return (
    <Box
      id={'header'}
      width='100%'
      overflow='visible'
      position='sticky'
      top={0}
      zIndex={100}
      px={[1, 4]}
      py={[2, 2, 1, 0, 0]}
      bg='foreground'
      boxShadow='0px 4px 5px rgba(0, 0, 0, 0.1)'
    >
      <SimpleGrid columns={14} spacing={3} width='100%' h='full' py={!isPrivateRoute ? [0, 0, 1, 2, 2] : 0}>
        <GridItem colSpan={[5, 5, 5, 2, 3]} h='full'>
          <LogoSection isUserOnPublicRoute={isUserOnPublicRoute} location={location} setLocation={setLocation} />
        </GridItem>
        <GridItem colSpan={[0, 0, 0, 8, 7]} overflow={['hidden', 'hidden', 'hidden', 'initial', 'initial']} h='full'>
          <LinksSection
            isUserOnPublicRoute={isUserOnPublicRoute}
            location={location}
            setLocation={setLocation}
            isPrivateRoute={isPrivateRoute}
          />
        </GridItem>
        <GridItem colSpan={[8, 8, 8, 4, 4]}>
          <ProfileAndAddonsSection
            isUserOnPublicRoute={isUserOnPublicRoute}
            location={location}
            setLocation={setLocation}
            isPrivateRoute={isPrivateRoute}
          />
        </GridItem>
      </SimpleGrid>
    </Box>
  );
};

export default Header;
