import { chakra } from '@chakra-ui/react'; // @ts-ignore
import { ReactComponent as _GPAIcon } from './GPA.svg'; // @ts-ignore
import { ReactComponent as _ActIcon } from './act-icon.svg'; // @ts-ignore
import { ReactComponent as _ActivitiesIcon } from './activities.svg'; // @ts-ignore
import { ReactComponent as _AutoSavedIcon } from './auto-saved.svg'; // @ts-ignore
import { ReactComponent as _AutoSavingIcon } from './auto-saving.svg'; // @ts-ignore
import _BeanIcon from './bean.svg'; // @ts-ignore
import { ReactComponent as _BoldIcon } from './bold.svg'; // @ts-ignore
import { ReactComponent as _BulletsIcon } from './bullets.svg'; // @ts-ignore
import { ReactComponent as _CalendarBasicIcon } from './calendar-basic.svg'; // @ts-ignore
import { ReactComponent as _CalendarIcon } from './calendar.svg'; // @ts-ignore
import { ReactComponent as _CampusIcon } from './campus_life.svg'; // @ts-ignore
import { ReactComponent as _CheckGreenIcon } from './check-green.svg'; // @ts-ignore
import { ReactComponent as _CheckIcon } from './check-purple.svg'; // @ts-ignore
import { ReactComponent as _ClipboardIcon } from './clipboard.svg'; // @ts-ignore
import { ReactComponent as _CopyIcon } from './copy-button.svg'; // @ts-ignore
import { ReactComponent as _CrossIcon } from './cross.svg'; // @ts-ignore
import { ReactComponent as _DislikeIcon } from './dislike.svg'; // @ts-ignore
import { ReactComponent as _DollarIcon2 } from './dollar-sign.svg'; // @ts-ignore
import { ReactComponent as _DollarIcon } from './dollar_sign.svg'; // @ts-ignore
import { ReactComponent as _EditIcon } from './edit.svg'; // @ts-ignore
import { ReactComponent as _PlanIcon } from './essay-plan.svg'; // @ts-ignore
import { ReactComponent as _ReviewIcon } from './essay-review.svg'; // @ts-ignore
import { ReactComponent as _EssaysIcon } from './essays.svg'; // @ts-ignore
import { ReactComponent as _FilterIcon } from './filter.svg'; // @ts-ignore
import { ReactComponent as _FreeIcon } from './free-icon.svg'; // @ts-ignore
import { ReactComponent as _GpaIcon } from './gpa-icon.svg'; // @ts-ignore
import { ReactComponent as _GraduationCap } from './graduation-cap.svg'; // @ts-ignore
import { ReactComponent as _CapIcon } from './graduation_cap.svg'; // @ts-ignore
import { ReactComponent as _GreekBuildingIcon } from './greek_building.svg'; // @ts-ignore
import { ReactComponent as _HighlightIcon } from './highlighter.svg'; // @ts-ignore
import { ReactComponent as _InfoIcon } from './info.svg'; // @ts-ignore
import { ReactComponent as _ItalicIcon } from './italic.svg'; // @ts-ignore
import { ReactComponent as _LikeIcon } from './like.svg'; // @ts-ignore
import { ReactComponent as _LinkIcon } from './link.svg'; // @ts-ignore
import { ReactComponent as _PinIcon } from './location-icon.svg'; // @ts-ignore
import { ReactComponent as _KollegioLogo } from './logoemblem.svg'; // @ts-ignore
import { ReactComponent as _MapPinIcon } from './map-pin.svg'; // @ts-ignore
import { ReactComponent as _MergeIcon } from './merge-idea.svg'; // @ts-ignore
import { ReactComponent as _OpenBookIcon } from './open-book.svg'; // @ts-ignore
import { ReactComponent as _TypeIcon } from './recommendations_type.svg'; // @ts-ignore
import { ReactComponent as _RedoIcon } from './redo.svg'; // @ts-ignore
import { ReactComponent as _ResetIcon } from './regenerate.svg'; // @ts-ignore
import { ReactComponent as _RewordIcon } from './reword.svg'; // @ts-ignore
import { ReactComponent as _RobotIcon } from './robo-thumbnail.svg'; // @ts-ignore
import { ReactComponent as _SatIcon } from './sat-icon.svg'; // @ts-ignore
import { ReactComponent as _SATIcon } from './sat.svg'; // @ts-ignore
import { ReactComponent as _SendIcon } from './send-arrow-icon.svg'; // @ts-ignore
import { ReactComponent as _SendChatIcon } from './send.svg'; // @ts-ignore
import { ReactComponent as _ShareIcon } from './share.svg'; // @ts-ignore
import { ReactComponent as _FileIcon } from './single-prompt.svg'; // @ts-ignore
import { ReactComponent as _SparksIcon } from './spark.svg'; // @ts-ignore
import { ReactComponent as _SparkleIcon } from './sparkle.svg'; // @ts-ignore
import { ReactComponent as _SuggestionIcon } from './suggestion.svg'; // @ts-ignore
import { ReactComponent as _LengthenIcon } from './text-long.svg'; // @ts-ignore
import { ReactComponent as _ShortenIcon } from './text-short.svg'; // @ts-ignore
import { ReactComponent as _TickIcon } from './tick_icon.svg'; // @ts-ignore
import { ReactComponent as _TransitionIcon } from './transition.svg'; // @ts-ignore
import { ReactComponent as _UnderlineIcon } from './underline.svg'; // @ts-ignore
import { ReactComponent as _UndoIcon } from './undo.svg'; // @ts-ignore
import { ReactComponent as _UploadIcon } from './upload-activity.svg'; // @ts-ignore
import { ReactComponent as _UserIcon } from './user.svg'; // @ts-ignore
import { ReactComponent as _WarningIcon } from './warning-icon.svg'; // @ts-ignore

const EditIcon = chakra(_EditIcon);
const UndoIcon = chakra(_UndoIcon);
const RedoIcon = chakra(_RedoIcon);
const BoldIcon = chakra(_BoldIcon);
const ItalicIcon = chakra(_ItalicIcon);
const UnderlineIcon = chakra(_UnderlineIcon);
const BulletsIcon = chakra(_BulletsIcon);
const LengthenIcon = chakra(_LengthenIcon);
const ShortenIcon = chakra(_ShortenIcon);
const RewordIcon = chakra(_RewordIcon);
const TransitionIcon = chakra(_TransitionIcon);
const MergeIcon = chakra(_MergeIcon);
const SuggestionIcon = chakra(_SuggestionIcon);
const SparkIcon = chakra(_SparksIcon);
const SendIcon = chakra(_SendIcon);
const RobotIcon = chakra(_RobotIcon);
const SparkleIcon = chakra(_SparkleIcon);
const PlanIcon = chakra(_PlanIcon);
const ReviewIcon = chakra(_ReviewIcon);
const CrossIcon = chakra(_CrossIcon);
const HighlightIcon = chakra(_HighlightIcon);
const AutosavedIcon = chakra(_AutoSavedIcon);
const AutosavingIcon = chakra(_AutoSavingIcon);
const LikeIcon = chakra(_LikeIcon);
const DislikeIcon = chakra(_DislikeIcon);
const OpenBookIcon = chakra(_OpenBookIcon);
const CheckIcon = chakra(_CheckIcon);
const TypeIcon = chakra(_TypeIcon);
const CheckGreenIcon = chakra(_CheckGreenIcon);
const CampusIcon = chakra(_CampusIcon);
const CapIcon = chakra(_CapIcon);
const DollarIcon = chakra(_DollarIcon);
const CalendarIcon = chakra(_CalendarIcon);
const SATIcon = chakra(_SATIcon);
const ShareIcon = chakra(_ShareIcon);
const CopyIcon = chakra(_CopyIcon);
const LinkIcon = chakra(_LinkIcon);
const WarningIcon = chakra(_WarningIcon);
const FileIcon = chakra(_FileIcon);
const SendChatIcon = chakra(_SendChatIcon);
const ResetIcon = chakra(_ResetIcon);
const UserIcon = chakra(_UserIcon);
const KollegioLogo = chakra(_KollegioLogo);
const ActivitiesIcon = chakra(_ActivitiesIcon);
const EssaysIcon = chakra(_EssaysIcon);
const GreekBuildingIcon = chakra(_GreekBuildingIcon);
const GpaIcon = chakra(_GpaIcon);
const ActIcon = chakra(_ActIcon);
const SatIcon = chakra(_SatIcon);
const FreeIcon = chakra(_FreeIcon);
const PinIcon = chakra(_PinIcon);
const MapPinIcon = chakra(_MapPinIcon);
const CalendarBasicIcon = chakra(_CalendarBasicIcon);
const ClipboardIcon = chakra(_ClipboardIcon);
const UploadIcon = chakra(_UploadIcon);
const TickIcon = chakra(_TickIcon);
const DollarIcon2 = chakra(_DollarIcon2);
const InfoIcon = chakra(_InfoIcon);
const GPAIcon = chakra(_GPAIcon);
const GraduationCap = chakra(_GraduationCap);
const FilterIcon = chakra(_FilterIcon);

export {
  ActIcon,
  ActivitiesIcon,
  AutosavedIcon,
  AutosavingIcon,
  BoldIcon,
  BulletsIcon,
  CalendarBasicIcon,
  CalendarIcon,
  CampusIcon,
  CapIcon,
  CheckGreenIcon,
  CheckIcon,
  ClipboardIcon,
  CopyIcon,
  CrossIcon,
  DislikeIcon,
  DollarIcon,
  DollarIcon2,
  EditIcon,
  EssaysIcon,
  FileIcon,
  FilterIcon,
  FreeIcon,
  GPAIcon,
  GpaIcon,
  GraduationCap,
  GreekBuildingIcon,
  HighlightIcon,
  InfoIcon,
  ItalicIcon,
  KollegioLogo,
  LengthenIcon,
  LikeIcon,
  LinkIcon,
  MapPinIcon,
  MergeIcon,
  OpenBookIcon,
  PinIcon,
  PlanIcon,
  RedoIcon,
  ResetIcon,
  ReviewIcon,
  RewordIcon,
  RobotIcon,
  SATIcon,
  SatIcon,
  SendChatIcon,
  SendIcon,
  ShareIcon,
  ShortenIcon,
  SparkIcon,
  SparkleIcon,
  SuggestionIcon,
  TickIcon,
  TransitionIcon,
  TypeIcon,
  UnderlineIcon,
  UndoIcon,
  UploadIcon,
  UserIcon,
  WarningIcon,
  _BeanIcon,
};
