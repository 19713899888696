import { Text } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation
import { userReferralCodeAtom } from '../../../Atoms/App/user';
import useMixpanel from '../../../lib/Analytics/Mixpanel/useMixpanel'; // @ts-ignore
import EssayWritingMan from './../../../Assets/images/essay-writing.svg'; // @ts-ignore
import GiftCard from './../../../Assets/images/gift-card.svg'; // @ts-ignore
import StanfordStudyGroup from './../../../Assets/images/stanford-study-group.svg'; // @ts-ignore
import TypingWoman from './../../../Assets/images/typing-woman.svg';
import ReferralModal from './ReferralModal';

const REFERRAL_MODAL_IMAGES = [GiftCard, StanfordStudyGroup, EssayWritingMan, TypingWoman];

const REFERRAL_MODAL_TEXTS = [
  <Text key={0} color={'gray17'} fontSize={'md'} fontWeight={500} lineHeight={'150%'} fontStyle={'normal'}>
    Bring buddies on board, and you’ll score a{' '}
    <Text as='span' fontSize={'md'} color={'#0E0E2C'} fontWeight={700} lineHeight={'normal'} fontStyle={'normal'}>
      chance to win $50!
    </Text>
  </Text>,
  <Text key={1} color={'gray17'} fontSize={'md'} fontWeight={500} lineHeight={'150%'} fontStyle={'normal'}>
    <Text as='span' fontSize={'md'} color={'#0E0E2C'} fontWeight={700} lineHeight={'normal'} fontStyle={'normal'}>
      Bring friends onboard,
    </Text>{' '}
    and we’ll hook you up with a Stanford whiz or a top college alum for an exclusive Q&A session.
  </Text>,
  <Text key={2} color={'gray17'} fontSize={'md'} fontWeight={500} lineHeight={'150%'} fontStyle={'normal'}>
    <Text as='span' fontSize={'md'} color={'#0E0E2C'} fontWeight={700} lineHeight={'normal'} fontStyle={'normal'}>
      Round up friends,
    </Text>{' '}
    and your essays get the VIP treatment from a top university student or alum!
  </Text>,
  <Text key={3} color={'gray17'} fontSize={'md'} fontWeight={500} lineHeight={'150%'} fontStyle={'normal'}>
    Rally friends, and gain access to{' '}
    <Text as='span' fontSize={'md'} color={'#0E0E2C'} fontWeight={700} lineHeight={'normal'} fontStyle={'normal'}>
      Successful Common App PDFs
    </Text>{' '}
    to boost your application game!
  </Text>,
];

const REFERRAL_REWARDS = ['Raffle Entry', 'Q/A', 'Feedback', 'PDFs'];

const Referral: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [randomNumber, setRandomNumber] = useState(0);
  const referralCode = useAtomValue(userReferralCodeAtom);
  const { trackEvent } = useMixpanel();
  const location = useLocation(); // Get the current location

  const sessionCount = parseInt(localStorage.getItem('sessionCount') || '0', 10);
  const modalDisplayCount = parseInt(localStorage.getItem('referralModalDisplayCount') || '0', 10);

  const generateRandomNumber = () => {
    const random = Math.floor(Math.random() * 4);
    setRandomNumber(random);
    return random;
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const openModal = useCallback(
    (generatedRandomNumber) => {
      setModalOpen(true);
      trackEvent('Referral Modal Notification', { modalName: REFERRAL_REWARDS[generatedRandomNumber] });
      const newModalDisplayCount = modalDisplayCount + 1;
      localStorage.setItem('referralModalDisplayCount', newModalDisplayCount.toString());
    },
    [modalDisplayCount, trackEvent]
  );

  useEffect(() => {
    const isBragSheetRoute = location.pathname.startsWith('/brag/');

    if (
      !isBragSheetRoute &&
      modalDisplayCount < 4 &&
      sessionCount > 1 &&
      (sessionCount === 2 || (sessionCount - 1) % 3 === 0)
    ) {
      const timer = setTimeout(() => {
        const generatedNumber = generateRandomNumber();
        openModal(generatedNumber);
        localStorage.setItem('sessionCount', (sessionCount + 1).toString());
      }, 4 * 60 * 1000);

      return () => clearTimeout(timer);
    }

    return undefined;
  }, [location.pathname, modalDisplayCount, openModal, sessionCount]);

  return (
    <ReferralModal
      isOpen={isModalOpen}
      onClose={closeModal}
      imgSrc={REFERRAL_MODAL_IMAGES[randomNumber]}
      subHeading={REFERRAL_MODAL_TEXTS[randomNumber]}
      referralLink={`${process.env.REACT_APP_URL}/join?referrer=${referralCode}`}
      referralReward={REFERRAL_REWARDS[randomNumber]}
    />
  );
};

export default Referral;
