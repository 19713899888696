import { Stack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { APPLICATION_INFO } from '../../../Constants/scholarship';
import { IScholarship } from '../../../types/scholarships';
import InfoItem from './InfoItem';

interface IApplicationProps {
  data: IScholarship;
}

const Application: React.FC<IApplicationProps> = ({ data }) => (
  <VStack align='start' spacing={20} paddingY={3}>
    <Stack flexDirection={['column', 'column', 'row']} width={'100%'} spacing={10}>
      {APPLICATION_INFO.map((info) => {
        const value = data[info.key];

        if (info.visible(value)) {
          const formattedValue = info.format ? info.format(value) : value;

          return <InfoItem key={info.key} heading={info.heading} subheading={formattedValue} Icon={info.Icon} />;
        }

        return null;
      })}
    </Stack>
    <VStack width={'100%'} spacing={6} alignItems={'flex-start'}>
      <Text fontSize={['12px', '14px', '16px']} fontStyle='normal' fontWeight={500} lineHeight='150%' color='#ffffff'>
        {data.description}
      </Text>
    </VStack>
  </VStack>
);

export default Application;
