/* eslint-disable max-len */
import {
  CalendarBasicIcon,
  ClipboardIcon,
  DollarIcon2,
  GPAIcon,
  GraduationCap,
  InfoIcon,
  MapPinIcon,
  OpenBookIcon,
} from '../Assets/icons/index';

export const OVERVIEW_INFO = [
  {
    heading: 'Type',
    Icon: InfoIcon,
    key: 'need_based',
    visible: (value: unknown) => (value ? true : false),
    format: (value: boolean) => (value ? 'Need Based' : ''),
  },
  {
    heading: 'Type',
    Icon: InfoIcon,
    key: 'merit_based',
    visible: (value: unknown) => (value ? true : false),
    format: (value: boolean) => (value ? 'Merit Based' : ''),
  },
  {
    heading: 'Coverage',
    Icon: DollarIcon2,
    key: 'max_coverage',
    visible: (value: unknown) => value !== null && value !== undefined && value !== '',
    format: (value: number) => `$${value}`,
  },
  {
    heading: 'Location',
    Icon: MapPinIcon,
    key: 'country',
    visible: (value: string[]) => value && value.length > 0,
    format: (value: string[] | string) => (Array.isArray(value) ? value.join(', ') : value),
  },
];

export const ELIGIBILITY_INFO = [
  {
    heading: 'Field of Study',
    Icon: OpenBookIcon,
    key: 'fields_of_study',
    visible: (value: string[]) => value && value.length > 0,
    format: (value: string[] | string) => (Array.isArray(value) ? value.join(', ') : value),
  },
  {
    heading: 'Min. GPA',
    Icon: GPAIcon,
    key: 'min_GPA',
    visible: (value: unknown) => value !== null && value !== undefined && value !== '',
    format: (value: string) => `> ${parseFloat(value).toFixed(1)}`,
  },
  {
    heading: 'Essay',
    Icon: ClipboardIcon,
    key: 'requires_essay',
    visible: (value: unknown) => value !== null && value !== undefined && value !== '',
    format: (value: boolean) => (value ? 'Essay Required' : 'No Essay Required'),
  },
  {
    heading: 'Year of School',
    Icon: GraduationCap,
    key: 'school_year',
    visible: (value: string[]) => value && value.length > 0,
    format: (value: string[] | string) => (Array.isArray(value) ? value.join(', ') : value),
  },
];

export const APPLICATION_INFO = [
  {
    heading: 'Application Opens',
    Icon: CalendarBasicIcon,
    key: 'application_open',
    visible: (value: unknown) => value !== null && value !== undefined && value !== '',
    format: (value: string) =>
      `${new Date(value).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })}`,
  },
  {
    heading: 'Deadline',
    Icon: CalendarBasicIcon,
    key: 'deadline',
    visible: (value: unknown) => value !== null && value !== undefined && value !== '',
    format: (value: string) =>
      `${new Date(value).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })}`,
  },
];

export const SIDE_PANEL_INFO = [
  {
    heading: 'Type',
    Icon: InfoIcon,
    key: 'need_based',
    visible: (value: unknown) => (value ? true : false),
    format: (value: boolean) => (value ? 'Need Based' : ''),
  },
  {
    heading: 'Type',
    Icon: InfoIcon,
    key: 'merit_based',
    visible: (value: unknown) => (value ? true : false),
    format: (value: unknown) => (value ? 'Merit Based' : ''),
  },
  {
    heading: 'Coverage',
    Icon: DollarIcon2,
    key: 'max_coverage',
    visible: (value: unknown) => value !== null && value !== undefined && value !== '',
    format: (value: unknown) => `$${value}`,
  },

  {
    heading: 'Field of Study',
    Icon: OpenBookIcon,
    key: 'fields_of_study',
    visible: (value: string[]) => value && value.length > 0,
    format: (value: string[] | string) => (Array.isArray(value) ? value.join(', ') : value),
  },
  {
    heading: 'Location',
    Icon: MapPinIcon,
    key: 'country',
    visible: (value: string[]) => value && value.length > 0,
    format: (value: string[] | string) => (Array.isArray(value) ? value.join(', ') : value),
  },
];

export const REQUIREMENT_OPTIONS = [
  {
    label: 'No min. GPA',
    value: 'min_GPA:null',
    tooltip: 'No minimum Grade Point Average required',
  },
  {
    label: 'No Essay',
    value: 'requires_essay:false',
    tooltip: 'No essay is required for application',
  },
];

export const SCHOLARSHIP_TYPE_OPTIONS = [
  {
    label: 'Need-Based',
    value: 'need_based:true',
    tooltip: 'Scholarship awarded based on financial need',
  },
  {
    label: 'Merit-Based',
    value: 'merit_based:true',
    tooltip: 'Scholarship awarded based on academic or other merits',
  },
];

export const DEADLINE_WITHIN_OPTIONS = [
  {
    label: '1 Month',
    value: `deadline:${JSON.stringify({ date: new Date().toISOString(), rangeInMonths: 1 })}`,
    tooltip: 'Deadlines within the next 1 month',
  },
  {
    label: '3 Months',
    value: `deadline:${JSON.stringify({ date: new Date().toISOString(), rangeInMonths: 3 })}`,
    tooltip: 'Deadlines within the next 3 months',
  },
  {
    label: '6 Months',
    value: `deadline:${JSON.stringify({ date: new Date().toISOString(), rangeInMonths: 6 })}`,
    tooltip: 'Deadlines within the next 6 months',
  },
];

export const SCHOLARSHIP_CARD_INFO_ITEMS = [
  {
    Icon: CalendarBasicIcon,
    key: 'deadline',
    isVisible: (value: unknown) => (value ? true : false),
    formatFn: (value: string) =>
      value
        ? `Deadline ${new Date(value).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })}`
        : '',
  },
  {
    Icon: ClipboardIcon,
    key: 'requires_essay',
    isVisible: (value: unknown) => (value === null || value === undefined ? false : true),
    formatFn: (value: boolean) => (value ? 'Essay req.' : 'No Essay req.'),
  },
  {
    Icon: DollarIcon2,
    key: 'max_coverage',
    isVisible: (value: unknown) => (value === null || value === undefined ? false : true),
    formatFn: (value: number) => value,
  },
];

// 14
export const SCHOOL_YEAR_FILTER_OPTIONS = [
  {
    label: 'High School',
    value: 'school_year:[High School,GED]',
    tooltip: 'Includes individuals who have completed or are currently pursuing high school or GED-level education.',
  },
  {
    label: 'Undergraduate Level',
    value: "school_year:[1-year Certificate,Associate Degree,Bachelor's Degree]",
    tooltip:
      "Includes individuals pursuing or who have completed an undergraduate education, such as a certificate, associate degree, or bachelor's degree.",
  },
  {
    label: 'Graduate Level',
    value: 'school_year:[Graduate Degree,Law Degree,MFA,MBA,Doctor of Medicine,PhD]',
    tooltip:
      "Includes individuals pursuing or who have completed a graduate-level education, such as a master's degree, law degree, MBA, or PhD.",
  },
  {
    label: 'Professional Certification',
    value: 'school_year:[Professional Certification,Professional Micro-credential,Professional Badge]',
    tooltip:
      'Includes individuals who have completed or are pursuing a professional certification or specialized micro-credential in a specific field.',
  },
];

// 15
export const CITIZENSHIP_REQUIREMENTS = [
  {
    label: 'US Citizens and Nationals',
    value: 'citizenship_requirements:[U.S. Citizen,U.S. National]',
    tooltip: 'Includes U.S. Citizens and U.S. Nationals.',
  },
  {
    label: 'Permanent Residents',
    value: 'citizenship_requirements:[Permanent Resident,Conditional Permanent Resident]',
    tooltip: 'Includes Permanent Residents and Conditional Permanent Residents.',
  },
  {
    label: 'Canadian Citizens',
    value: 'citizenship_requirements:[Canadian Citizen]',
    tooltip: 'Includes individuals holding Canadian citizenship.',
  },
  {
    label: 'DACA Status',
    value: 'citizenship_requirements:[Current DACA Status,Pending DACA Application]',
    tooltip: 'Includes individuals with current or pending DACA status.',
  },
  {
    label: 'Humanitarian Categories',
    value: 'citizenship_requirements:[Asylum-Seeker or Asylee,Refugee,Humanitarian Parolee,Cuban or Haitian Entrant]',
    tooltip: 'Includes asylees, refugees, humanitarian parolees, and Cuban or Haitian entrants.',
  },
  {
    label: 'State-Specific Eligibility',
    value: 'citizenship_requirements:[California AB 540]',
    tooltip: 'Includes individuals meeting California AB 540 eligibility.',
  },
  {
    label: 'FAFSA-Eligible Non-Citizens',
    value: 'citizenship_requirements:[FAFSA-Eligible Non-Citizen]',
    tooltip: 'Includes non-citizens eligible for FAFSA applications.',
  },
  {
    label: 'Others',
    value: 'citizenship_requirements:[Not a U.S. Citizen,Other]',
    tooltip: 'Includes individuals not categorized elsewhere, such as "Other" or "Not a U.S. Citizen".',
  },
];

export const DEMOGRAPHICS = [
  {
    label: 'Women',
    value: 'demographics:[Female,Gender - Female]',
    tooltip: 'Includes individuals who identify as female or women.',
  },
  {
    label: 'Men',
    value: 'demographics:[Male,Gender - Male]',
    tooltip: 'Includes individuals who identify as male or men.',
  },
  {
    label: 'LGBTQIA+ Community',
    value:
      'demographics:[Transgender Male to Female,Transgender Female to Male,Nonbinary,Genderqueer,Two-Spirit,Intersex,Bisexual,Asexual,Lesbian,Gay,Transgender,LGBTQIA+,LGBTQ Ally]',
    tooltip:
      'Includes a range of gender identities, sexual orientations, and allies, such as transgender, nonbinary, LGBTQIA+, and others.',
  },
  {
    label: 'African American',
    value:
      'demographics:[African,African American,Black,Black or African American,Race - African American,Race - Black or African American]',
    tooltip: 'Includes individuals identifying as African American or Black.',
  },
  {
    label: 'Hispanic/Latino',
    value: 'demographics:[Latino,Mexican,Hispanic,Spanish,Caribbean,Brazilian,Cuban,Haitian,Jamaican]',
    tooltip: 'Includes individuals identifying as Hispanic, Latino, Caribbean, Jamaican or related ethnicities.',
  },
  {
    label: 'Native American Tribes',
    value:
      'demographics:[Mohawk,Iroquois,Chickasaw,Hoopa,Cherokee,Paiute,Sioux,Cayuga,Shinnecock,Onondaga,Kaw,Oneida,Sac and Fox Nation,Apache,Choctaws,Navajo,Cheyenne,Poospatuck,Iowa Tribe of Oklahoma,Gila River Indian Community,Seneca Nation of Indians,Hopi,Tuscarora,Chippewa,Wyandotte,Menominee,Osage,Ottawa,Shoshone,Wichita,Fort Yuma Quechan,Laguna Pueblo,American Indian,Alaska Native,American Indian or Alaska Native]',
    tooltip: 'Includes various Native American tribes and communities.',
  },
  {
    label: 'Asian',
    value: 'demographics:[Chinese,Filipino,Indian,South Asian,Japanese,Korean,Asian]',
    tooltip: 'Includes individuals identifying with Asian ethnicities and backgrounds.',
  },
  {
    label: 'Pacific Islander',
    value: 'demographics:[Native Hawaiian or Other Pacific Islander,Native Pacific Islander]',
    tooltip: 'Includes Native Hawaiian and Pacific Islander communities.',
  },
  {
    label: 'European',
    value:
      'demographics:[Norwegian,Greek,Italian,Spanish,German,Scottish,Czech,Slovene,Swedish,Finnish,Polish,Armenian]',
    tooltip: 'Includes individuals of European descent or heritage.',
  },
  {
    label: 'Middle Eastern',
    value: 'demographics:[Arabic,Middle Eastern]',
    tooltip: 'Includes individuals identifying as Middle Eastern or Arabic.',
  },
  {
    label: 'Jewish',
    value: 'demographics:[Jewish]',
    tooltip: 'Includes individuals identifying as Jewish.',
  },
  {
    label: 'Self-Identified or Other',
    value: 'demographics:[Prefer to Self-Identify,Other]',
    tooltip: 'Includes individuals preferring to self-identify or not fitting other categories.',
  },
];

export const FIELDS_OF_STUDY_OPTIONS = [
  { value: 'Biological and Physical Sciences', label: 'Biological and Physical Sciences' },
  { value: 'Aviation and Space Law', label: 'Aviation and Space Law' },
  {
    value: 'Ventilation and Refrigeration Maintenance Technology/Technician',
    label: 'Ventilation and Refrigeration Maintenance Technology/Technician',
  },
  { value: 'Veterinary Technician and Assistant', label: 'Veterinary Technician and Assistant' },
  { value: 'Math', label: 'Math' },
  { value: 'Accounting and Business/Management', label: 'Accounting and Business/Management' },
  { value: 'Other', label: 'Other' },
  { value: 'Risk Management', label: 'Risk Management' },
  { value: 'Medical Office Management', label: 'Medical Office Management' },
  { value: 'Insurance', label: 'Insurance' },
  { value: 'Data Processing', label: 'Data Processing' },
  { value: 'Commercial Vehicle Operation', label: 'Commercial Vehicle Operation' },
  { value: 'Nursing Science', label: 'Nursing Science' },
  { value: 'Cybersecurity', label: 'Cybersecurity' },
  { value: 'Public Health Nursing', label: 'Public Health Nursing' },
  { value: 'Fine and Studio Arts', label: 'Fine and Studio Arts' },
  { value: 'Systems Engineering', label: 'Systems Engineering' },
  {
    value: 'Airline/Commercial/Professional Pilot and Flight Crew',
    label: 'Airline/Commercial/Professional Pilot and Flight Crew',
  },
  { value: 'Korean Language', label: 'Korean Language' },
  { value: 'Art/Art Studies', label: 'Art/Art Studies' },
  { value: 'Law', label: 'Law' },
  { value: 'Japanese History', label: 'Japanese History' },
  { value: 'Religious Studies', label: 'Religious Studies' },
  { value: 'Dental Support Services and Allied Professions', label: 'Dental Support Services and Allied Professions' },
  { value: 'Computer Installation and Repair', label: 'Computer Installation and Repair' },
  { value: 'Printmaking', label: 'Printmaking' },
  { value: 'Civil Engineering Technician', label: 'Civil Engineering Technician' },
  { value: 'Engineering Technology', label: 'Engineering Technology' },
  { value: 'Rehabilitation and Therapy', label: 'Rehabilitation and Therapy' },
  { value: 'School Health Nursing', label: 'School Health Nursing' },
  { value: 'Equine Studies', label: 'Equine Studies' },
  { value: 'Leadership', label: 'Leadership' },
  { value: 'Sexuality', label: 'Sexuality' },
  { value: 'Engineering Science', label: 'Engineering Science' },
  { value: 'Law Enforcement', label: 'Law Enforcement' },
  { value: 'Physician Assistant', label: 'Physician Assistant' },
  { value: 'General Construction Trades', label: 'General Construction Trades' },
  { value: 'English', label: 'English' },
  { value: 'Broadcast Journalism', label: 'Broadcast Journalism' },
  { value: 'Mathematics', label: 'Mathematics' },
  { value: 'Land Management', label: 'Land Management' },
  { value: 'Radio and Television Broadcasting Technician', label: 'Radio and Television Broadcasting Technician' },
  { value: 'Clinical Psychology', label: 'Clinical Psychology' },
  { value: 'Arts Management', label: 'Arts Management' },
  { value: 'Zoology and Entomology', label: 'Zoology and Entomology' },
  {
    value: 'Electrical Engineering Technologies/Technicians',
    label: 'Electrical Engineering Technologies/Technicians',
  },
  { value: 'Theology/Theological Studies', label: 'Theology/Theological Studies' },
  { value: 'Nursing/Registered Nurse (RN', label: 'Nursing/Registered Nurse (RN' },
  { value: 'Research and Experimental Psychology', label: 'Research and Experimental Psychology' },
  { value: 'Theater Education', label: 'Theater Education' },
  { value: 'International Education', label: 'International Education' },
  { value: 'Logistics Engineering', label: 'Logistics Engineering' },
  { value: 'Arts', label: 'Arts' },
  { value: 'Design', label: 'Design' },
  { value: 'Hospital Management', label: 'Hospital Management' },
  { value: 'Pottery', label: 'Pottery' },
  { value: 'Pharmacy and Pharmaceutical Sciences', label: 'Pharmacy and Pharmaceutical Sciences' },
  { value: 'Wood Carving', label: 'Wood Carving' },
  { value: 'Corrections', label: 'Corrections' },
  {
    value: 'Electrical/Electronics Maintenance and Repair Technology',
    label: 'Electrical/Electronics Maintenance and Repair Technology',
  },
  { value: 'Structural Engineering', label: 'Structural Engineering' },
  { value: 'Nonprofit and Public Management', label: 'Nonprofit and Public Management' },
  { value: 'Informatics Nursing', label: 'Informatics Nursing' },
  { value: 'Digital', label: 'Digital' },
  { value: 'Architectural Engineering Technician', label: 'Architectural Engineering Technician' },
  { value: 'Papermaking', label: 'Papermaking' },
  { value: 'Bioengineering and Biomedical Engineering', label: 'Bioengineering and Biomedical Engineering' },
  { value: 'Marine Sciences', label: 'Marine Sciences' },
  { value: 'Speech Communication and Rhetoric', label: 'Speech Communication and Rhetoric' },
  { value: 'Natural Resources/Conservation', label: 'Natural Resources/Conservation' },
  { value: 'Patriotism', label: 'Patriotism' },
  { value: 'Landscaping and Groundskeeping', label: 'Landscaping and Groundskeeping' },
  { value: 'Electronic Engineering', label: 'Electronic Engineering' },
  { value: 'Library Science and Administration', label: 'Library Science and Administration' },
  { value: 'Cast Metal Industry', label: 'Cast Metal Industry' },
  { value: 'Electrical Engineering Technician', label: 'Electrical Engineering Technician' },
  { value: 'Health and Physical Education/Fitness', label: 'Health and Physical Education/Fitness' },
  { value: 'Sports History', label: 'Sports History' },
  { value: 'Neonatal and Perinatal Nursing', label: 'Neonatal and Perinatal Nursing' },
  { value: 'Fashion and Apparel Merchandising', label: 'Fashion and Apparel Merchandising' },
  { value: 'Glass Art', label: 'Glass Art' },
  { value: 'HACR', label: 'HACR' },
  { value: 'Higher Education', label: 'Higher Education' },
  { value: "Women's Studies", label: "Women's Studies" },
  { value: 'Archeology', label: 'Archeology' },
  { value: 'Electronics Equipment Installation and Repair', label: 'Electronics Equipment Installation and Repair' },
  { value: 'Video Graphics and Special Effects', label: 'Video Graphics and Special Effects' },
  { value: 'Psychiatric/Mental Health Nurse/Nursing', label: 'Psychiatric/Mental Health Nurse/Nursing' },
  { value: 'Pharmaceutical Sciences', label: 'Pharmaceutical Sciences' },
  { value: 'ASN', label: 'ASN' },
  { value: 'Pashto Language', label: 'Pashto Language' },
  { value: 'Organ Transplants', label: 'Organ Transplants' },
  { value: 'Medical Technician', label: 'Medical Technician' },
  { value: 'Petroleum Engineering', label: 'Petroleum Engineering' },
  { value: 'Hydraulic Engineering', label: 'Hydraulic Engineering' },
  { value: 'Wildlife Biology', label: 'Wildlife Biology' },
  { value: 'Cantonese Language', label: 'Cantonese Language' },
  { value: 'Italian History', label: 'Italian History' },
  { value: 'English and Writing', label: 'English and Writing' },
  { value: 'Radio', label: 'Radio' },
  { value: 'Anthropology', label: 'Anthropology' },
  { value: 'Public Health Education', label: 'Public Health Education' },
  { value: 'Air Transportation', label: 'Air Transportation' },
  { value: 'Family and Consumer Studies', label: 'Family and Consumer Studies' },
  { value: 'Gerontology', label: 'Gerontology' },
  { value: 'Computer Graphics', label: 'Computer Graphics' },
  { value: 'Horse Husbandry/Equine Science and Management', label: 'Horse Husbandry/Equine Science and Management' },
  { value: 'Electrical', label: 'Electrical' },
  { value: 'Physical Education Teaching and Coaching', label: 'Physical Education Teaching and Coaching' },
  { value: 'Music Performance', label: 'Music Performance' },
  { value: 'Textiles', label: 'Textiles' },
  { value: 'Interpretation', label: 'Interpretation' },
  { value: 'HVAC', label: 'HVAC' },
  { value: 'BSN', label: 'BSN' },
  { value: 'Aerospace Engineering Technician', label: 'Aerospace Engineering Technician' },
  { value: 'Equine Science', label: 'Equine Science' },
  { value: 'Boating Industry', label: 'Boating Industry' },
  {
    value: 'Veterinary/Animal Health Technology/Technician and Veterinary Assistant',
    label: 'Veterinary/Animal Health Technology/Technician and Veterinary Assistant',
  },
  { value: 'Neuroscience and Neurobiology', label: 'Neuroscience and Neurobiology' },
  { value: 'Esthetician and Skin Care', label: 'Esthetician and Skin Care' },
  { value: 'Interactive Technology', label: 'Interactive Technology' },
  { value: 'Community Health Services and Counseling', label: 'Community Health Services and Counseling' },
  { value: 'Translation', label: 'Translation' },
  { value: 'Robotics and Automation Engineering Technician', label: 'Robotics and Automation Engineering Technician' },
  { value: 'Peace Studies and Conflict Resolution', label: 'Peace Studies and Conflict Resolution' },
  { value: 'Environmental Architecture and Design', label: 'Environmental Architecture and Design' },
  { value: 'Computer and Information Systems Security', label: 'Computer and Information Systems Security' },
  { value: 'Accounting and Finance', label: 'Accounting and Finance' },
  { value: 'Professional and Technical Writing', label: 'Professional and Technical Writing' },
  { value: 'Cooking and Related Culinary Arts', label: 'Cooking and Related Culinary Arts' },
  { value: 'Radiation Therapy', label: 'Radiation Therapy' },
  { value: 'Multimedia', label: 'Multimedia' },
  { value: 'Astronomy and Astrophysics', label: 'Astronomy and Astrophysics' },
  { value: 'Community Development', label: 'Community Development' },
  { value: 'Aerospace Engineering', label: 'Aerospace Engineering' },
  { value: 'Fire Science and Fire Fighting', label: 'Fire Science and Fire Fighting' },
  { value: 'Photography', label: 'Photography' },
  { value: 'Graphic Design', label: 'Graphic Design' },
  {
    value: 'Electromechanical Technology/Electromechanical Engineering Technology',
    label: 'Electromechanical Technology/Electromechanical Engineering Technology',
  },
  {
    value: 'Aeronautical/Aerospace Engineering Technology/Technician',
    label: 'Aeronautical/Aerospace Engineering Technology/Technician',
  },
  { value: 'Archaeology', label: 'Archaeology' },
  { value: 'Transportation', label: 'Transportation' },
  { value: 'Biomedical Sciences', label: 'Biomedical Sciences' },
  { value: 'Criminal Justice', label: 'Criminal Justice' },
  { value: 'Engineering Mechanics', label: 'Engineering Mechanics' },
  { value: 'Career Planning', label: 'Career Planning' },
  { value: 'Rural Affairs', label: 'Rural Affairs' },
  { value: 'Construction and Heavy Equipment Operation', label: 'Construction and Heavy Equipment Operation' },
  { value: 'Sculpture', label: 'Sculpture' },
  { value: 'Medical Laboratory Technician', label: 'Medical Laboratory Technician' },
  { value: 'Ocean Engineering', label: 'Ocean Engineering' },
  { value: 'Norwegian Studies', label: 'Norwegian Studies' },
  { value: 'Hydrology and Water Resources Science', label: 'Hydrology and Water Resources Science' },
  { value: 'Energy Law', label: 'Energy Law' },
  { value: 'Tax Law', label: 'Tax Law' },
  { value: 'Hospitality and Tourism Management', label: 'Hospitality and Tourism Management' },
  { value: 'Health and Medical Administrative Services', label: 'Health and Medical Administrative Services' },
  { value: 'Small Business Management', label: 'Small Business Management' },
  { value: 'Food Science', label: 'Food Science' },
  { value: 'Mechanics and Repair', label: 'Mechanics and Repair' },
  { value: 'German Language and Literature', label: 'German Language and Literature' },
  { value: 'Mechanics', label: 'Mechanics' },
  { value: 'Medical Records Administration', label: 'Medical Records Administration' },
  { value: 'Business Law', label: 'Business Law' },
  { value: 'African-American Studies', label: 'African-American Studies' },
  { value: 'Reading', label: 'Reading' },
  { value: 'Acoustical Engineering', label: 'Acoustical Engineering' },
  { value: 'Forensic Psychology', label: 'Forensic Psychology' },
  { value: 'Leisure and Recreational Activities', label: 'Leisure and Recreational Activities' },
  { value: 'Architectural Engineering', label: 'Architectural Engineering' },
  { value: 'Occupational Therapy/Therapist', label: 'Occupational Therapy/Therapist' },
  { value: 'Young Adult Libraries and Librarianship', label: 'Young Adult Libraries and Librarianship' },
  { value: 'Electrician', label: 'Electrician' },
  { value: 'Corporate Law', label: 'Corporate Law' },
  { value: 'Hotel and Motel Industry', label: 'Hotel and Motel Industry' },
  { value: 'Sonographer and Ultrasound Technician', label: 'Sonographer and Ultrasound Technician' },
  { value: 'Commercial Photography', label: 'Commercial Photography' },
  { value: 'Art', label: 'Art' },
  { value: 'Christian Studies', label: 'Christian Studies' },
  { value: 'Adult Development', label: 'Adult Development' },
  { value: 'Deaf Studies', label: 'Deaf Studies' },
  { value: 'Kinesiology and Exercise Science', label: 'Kinesiology and Exercise Science' },
  { value: 'British Studies', label: 'British Studies' },
  { value: 'Automotive Mechanics', label: 'Automotive Mechanics' },
  { value: 'Forensic Science and Technology', label: 'Forensic Science and Technology' },
  { value: 'Urban', label: 'Urban' },
  { value: 'Scottish Language', label: 'Scottish Language' },
  { value: 'Psychiatry and Mental Health Nursing', label: 'Psychiatry and Mental Health Nursing' },
  { value: 'Long Term Care Administration/Management', label: 'Long Term Care Administration/Management' },
  { value: 'Office Management', label: 'Office Management' },
  { value: 'Barbering', label: 'Barbering' },
  { value: 'Teaching Assistant', label: 'Teaching Assistant' },
  { value: 'Metallurgical Engineering', label: 'Metallurgical Engineering' },
  { value: 'Architectural and Building Sciences/Technology', label: 'Architectural and Building Sciences/Technology' },
  { value: 'Sports Reporting', label: 'Sports Reporting' },
  { value: 'Bible/Biblical Studies', label: 'Bible/Biblical Studies' },
  { value: 'Financial Planning', label: 'Financial Planning' },
  { value: 'Fine/Studio Arts', label: 'Fine/Studio Arts' },
  { value: 'Construction Management', label: 'Construction Management' },
  { value: 'Hair Design', label: 'Hair Design' },
  { value: 'Crop and Soil Sciences', label: 'Crop and Soil Sciences' },
  { value: 'Accounting', label: 'Accounting' },
  { value: 'Counseling Psychology', label: 'Counseling Psychology' },
  { value: 'Nuclear Medical Technician', label: 'Nuclear Medical Technician' },
  { value: 'Manufacturing Engineering', label: 'Manufacturing Engineering' },
  { value: 'Entertainment', label: 'Entertainment' },
  { value: 'Materials Science', label: 'Materials Science' },
  { value: 'Chemical Engineering', label: 'Chemical Engineering' },
  { value: 'Taxation', label: 'Taxation' },
  { value: 'Secondary Education and Teaching', label: 'Secondary Education and Teaching' },
  { value: 'Japanese Language and Literature', label: 'Japanese Language and Literature' },
  { value: 'Property Management', label: 'Property Management' },
  { value: 'Medical Social Work', label: 'Medical Social Work' },
  { value: 'Transportation Engineering', label: 'Transportation Engineering' },
  { value: 'Voice and Opera', label: 'Voice and Opera' },
  { value: 'Illustration', label: 'Illustration' },
  { value: 'Agricultural Technology', label: 'Agricultural Technology' },
  { value: 'Professional Pilot', label: 'Professional Pilot' },
  { value: 'Dental Hygiene/Hygienist', label: 'Dental Hygiene/Hygienist' },
  { value: 'English Language', label: 'English Language' },
  { value: 'Sign Language', label: 'Sign Language' },
  { value: 'Pediatrics', label: 'Pediatrics' },
  { value: 'Anesthesiology', label: 'Anesthesiology' },
  { value: 'Molecular Biology', label: 'Molecular Biology' },
  { value: 'Ceramics', label: 'Ceramics' },
  { value: 'Child Care Provider', label: 'Child Care Provider' },
  { value: 'Oratory', label: 'Oratory' },
  { value: 'Drafting and Design (CAD/CADD)', label: 'Drafting and Design (CAD/CADD)' },
  { value: 'Health Care Law', label: 'Health Care Law' },
  { value: 'Marine Science', label: 'Marine Science' },
  { value: 'Business Administration', label: 'Business Administration' },
  { value: 'Jewish Studies', label: 'Jewish Studies' },
  { value: 'Construction Engineering Technician', label: 'Construction Engineering Technician' },
  { value: 'Instrumentation Technician', label: 'Instrumentation Technician' },
  { value: 'Aeronautical Engineering', label: 'Aeronautical Engineering' },
  {
    value: 'Family and Consumer Economics and Related Services',
    label: 'Family and Consumer Economics and Related Services',
  },
  { value: 'Administration', label: 'Administration' },
  { value: 'Automotive Engineering Technician', label: 'Automotive Engineering Technician' },
  { value: 'Nuclear Engineering', label: 'Nuclear Engineering' },
  { value: 'Pastoral Counseling and Specialized Ministries', label: 'Pastoral Counseling and Specialized Ministries' },
  { value: 'Entrepreneurship', label: 'Entrepreneurship' },
  { value: 'Environmental Science', label: 'Environmental Science' },
  { value: 'Military Technologies and Applied Sciences', label: 'Military Technologies and Applied Sciences' },
  { value: 'Landscape Architecture', label: 'Landscape Architecture' },
  { value: 'Applied Horticulture/Horticulture Operations', label: 'Applied Horticulture/Horticulture Operations' },
  { value: 'Speech Language Pathology', label: 'Speech Language Pathology' },
  { value: 'Management and Operations', label: 'Management and Operations' },
  { value: 'Music Theory and Composition', label: 'Music Theory and Composition' },
  { value: 'Horticulture', label: 'Horticulture' },
  {
    value: 'Cosmetology and Related Personal Grooming Services',
    label: 'Cosmetology and Related Personal Grooming Services',
  },
  { value: 'Food Engineering', label: 'Food Engineering' },
  { value: 'Criminal Justice and Corrections', label: 'Criminal Justice and Corrections' },
  { value: 'Water resources', label: 'Water resources' },
  { value: 'Mandarin Language', label: 'Mandarin Language' },
  { value: 'Latin and Ancient Greek Studies', label: 'Latin and Ancient Greek Studies' },
  { value: 'Audiology', label: 'Audiology' },
  { value: 'Pre-Theology', label: 'Pre-Theology' },
  { value: 'Building and Construction', label: 'Building and Construction' },
  { value: 'Medical Assistants and Technicians', label: 'Medical Assistants and Technicians' },
  { value: 'Family and Community Services', label: 'Family and Community Services' },
  { value: 'Human Services', label: 'Human Services' },
  { value: 'Hospitality Administration/Management', label: 'Hospitality Administration/Management' },
  { value: 'Environmental Education', label: 'Environmental Education' },
  { value: 'Communication Disorders', label: 'Communication Disorders' },
  { value: 'Biochemistry', label: 'Biochemistry' },
  { value: 'Philosophy', label: 'Philosophy' },
  { value: 'Library and Information Science', label: 'Library and Information Science' },
  { value: 'Spanish Language and Literature', label: 'Spanish Language and Literature' },
  { value: 'Computer Systems Technician', label: 'Computer Systems Technician' },
  {
    value: 'Science Teacher Education/General Science Teacher Education',
    label: 'Science Teacher Education/General Science Teacher Education',
  },
  { value: 'Accounting Technician and Bookkeeping', label: 'Accounting Technician and Bookkeeping' },
  { value: 'Soil Science and Agronomy', label: 'Soil Science and Agronomy' },
  { value: 'Graphic Arts', label: 'Graphic Arts' },
  { value: 'Naval Science', label: 'Naval Science' },
  { value: 'School Libraries and Librarianship', label: 'School Libraries and Librarianship' },
  { value: 'Animation', label: 'Animation' },
  { value: 'Electrical Engineering', label: 'Electrical Engineering' },
  { value: 'Agricultural Communications', label: 'Agricultural Communications' },
  { value: 'Painting/Painter and Wall Coverer', label: 'Painting/Painter and Wall Coverer' },
  { value: 'Employment Law', label: 'Employment Law' },
  { value: 'English Language and Literature', label: 'English Language and Literature' },
  { value: 'Social Studies Teacher Education', label: 'Social Studies Teacher Education' },
  { value: 'Performing Arts', label: 'Performing Arts' },
  { value: 'Public Relations', label: 'Public Relations' },
  { value: 'Licensed Practical Nurse Training (LPN)', label: 'Licensed Practical Nurse Training (LPN)' },
  { value: 'Painting', label: 'Painting' },
  { value: 'Industrial Engineering', label: 'Industrial Engineering' },
  { value: 'Surgical Technologist', label: 'Surgical Technologist' },
  { value: 'Wildlife Management', label: 'Wildlife Management' },
  { value: 'Ceramic Arts and Ceramics', label: 'Ceramic Arts and Ceramics' },
  { value: 'Sports and Fitness Management', label: 'Sports and Fitness Management' },
  { value: 'Anesthesiology Nursing', label: 'Anesthesiology Nursing' },
  { value: 'Journalism', label: 'Journalism' },
  { value: 'Education Research and Evaluation', label: 'Education Research and Evaluation' },
  { value: 'High School Education', label: 'High School Education' },
  { value: 'Medical/Clinical Assistant', label: 'Medical/Clinical Assistant' },
  { value: 'Emergency Care Attendant', label: 'Emergency Care Attendant' },
  { value: 'Plant Science', label: 'Plant Science' },
  { value: 'Mathematics Teacher Education', label: 'Mathematics Teacher Education' },
  { value: 'Multi/Interdisciplinary Studies', label: 'Multi/Interdisciplinary Studies' },
  {
    value: 'Education/Teaching of Individuals with Vision Impairments Including Blindness',
    label: 'Education/Teaching of Individuals with Vision Impairments Including Blindness',
  },
  { value: 'Agricultural Teacher Education', label: 'Agricultural Teacher Education' },
  { value: 'German History', label: 'German History' },
  { value: 'Physics', label: 'Physics' },
  { value: 'Civil Engineering', label: 'Civil Engineering' },
  { value: 'Practice', label: 'Practice' },
  { value: 'Tourism', label: 'Tourism' },
  { value: 'Health and Wellness', label: 'Health and Wellness' },
  { value: 'Construction Engineering', label: 'Construction Engineering' },
  { value: 'Wildlife and Fisheries Management', label: 'Wildlife and Fisheries Management' },
  { value: 'Real Estate', label: 'Real Estate' },
  { value: 'Animal Health', label: 'Animal Health' },
  { value: 'Biomedical Sciences and Molecular Medicine', label: 'Biomedical Sciences and Molecular Medicine' },
  { value: 'Natural Resources Conservation and Research', label: 'Natural Resources Conservation and Research' },
  {
    value: 'Emergency Medical Technology/Technician (EMT Paramedic)',
    label: 'Emergency Medical Technology/Technician (EMT Paramedic)',
  },
  { value: 'Radiology Nursing', label: 'Radiology Nursing' },
  { value: 'Art History', label: 'Art History' },
  {
    value: 'Health Professions and Related Clinical Sciences',
    label: 'Health Professions and Related Clinical Sciences',
  },
  { value: 'Vocational Education', label: 'Vocational Education' },
  { value: 'Baking and Pastry Arts', label: 'Baking and Pastry Arts' },
  { value: 'Labor and Industrial Relations', label: 'Labor and Industrial Relations' },
  { value: 'Ornithology', label: 'Ornithology' },
  { value: 'Cardiovascular Technician', label: 'Cardiovascular Technician' },
  { value: 'Environmental Health', label: 'Environmental Health' },
  { value: 'Geography', label: 'Geography' },
  { value: 'Speech Impairments', label: 'Speech Impairments' },
  { value: 'Carpentry', label: 'Carpentry' },
  { value: 'Logistics and Supply Chain Management', label: 'Logistics and Supply Chain Management' },
  { value: 'Management', label: 'Management' },
  { value: 'Museum Studies', label: 'Museum Studies' },
  { value: 'Statistics', label: 'Statistics' },
  { value: 'Youth Ministry', label: 'Youth Ministry' },
  { value: 'Pipefitting/Pipefitter and Sprinkler Fitter', label: 'Pipefitting/Pipefitter and Sprinkler Fitter' },
  { value: 'Evolution', label: 'Evolution' },
  { value: 'Comparative', label: 'Comparative' },
  { value: 'Organizational Behavior Studies', label: 'Organizational Behavior Studies' },
  { value: 'Banking and Finance', label: 'Banking and Finance' },
  { value: 'Criminal Justice and Law Enforcement', label: 'Criminal Justice and Law Enforcement' },
  { value: 'Ranching', label: 'Ranching' },
  { value: 'French Language and Literature', label: 'French Language and Literature' },
  { value: 'Divinity', label: 'Divinity' },
  { value: 'Plastics Engineering', label: 'Plastics Engineering' },
  { value: 'Latin Language', label: 'Latin Language' },
  { value: 'Funeral Service and Mortuary Science', label: 'Funeral Service and Mortuary Science' },
  { value: 'Petroleum Industry', label: 'Petroleum Industry' },
  { value: 'Communications Technicians', label: 'Communications Technicians' },
  { value: 'Agricultural Mechanics and Machinery', label: 'Agricultural Mechanics and Machinery' },
  { value: 'Recreation', label: 'Recreation' },
  { value: 'Culinary Arts and Related Services', label: 'Culinary Arts and Related Services' },
  {
    value: 'Airframe Mechanics and Aircraft Maintenance Technology/Technician',
    label: 'Airframe Mechanics and Aircraft Maintenance Technology/Technician',
  },
  {
    value: 'Aeronautics/Aviation/Aerospace Science and Technology',
    label: 'Aeronautics/Aviation/Aerospace Science and Technology',
  },
  { value: 'Emergency Nursing', label: 'Emergency Nursing' },
  { value: 'Biological and Biomedical Sciences', label: 'Biological and Biomedical Sciences' },
  { value: 'Public Service', label: 'Public Service' },
  { value: 'Pollution', label: 'Pollution' },
  { value: 'Social Services', label: 'Social Services' },
  {
    value: "Children's Services Libraries and Librarianship",
    label: "Children's Services Libraries and Librarianship",
  },
  { value: 'Substance Abuse and Addiction Counseling', label: 'Substance Abuse and Addiction Counseling' },
  { value: 'Biotechnology', label: 'Biotechnology' },
  { value: 'Urban Studies and Affairs', label: 'Urban Studies and Affairs' },
  { value: 'Occupational Therapy', label: 'Occupational Therapy' },
  { value: 'Film and Video Studies', label: 'Film and Video Studies' },
  {
    value: 'Junior High/Intermediate/Middle School Education and Teaching',
    label: 'Junior High/Intermediate/Middle School Education and Teaching',
  },
  { value: 'Aviation Management and Operations', label: 'Aviation Management and Operations' },
  { value: 'Energy Engineering', label: 'Energy Engineering' },
  { value: 'Environmental Law', label: 'Environmental Law' },
  { value: 'Health/Health Care Administration/Management', label: 'Health/Health Care Administration/Management' },
  { value: 'Physical Therapy Technician', label: 'Physical Therapy Technician' },
  { value: 'Computational and Applied Mathematics', label: 'Computational and Applied Mathematics' },
  { value: 'Microbiology', label: 'Microbiology' },
  { value: 'Cyber/Computer Forensics and Counterterrorism', label: 'Cyber/Computer Forensics and Counterterrorism' },
  { value: 'Drawing', label: 'Drawing' },
  { value: 'Engineering Physics', label: 'Engineering Physics' },
  { value: 'Animal Sciences', label: 'Animal Sciences' },
  { value: 'Ophthalmic Technician/Technologist', label: 'Ophthalmic Technician/Technologist' },
  { value: 'Radio and Television', label: 'Radio and Television' },
  { value: 'Clothing', label: 'Clothing' },
  { value: 'Pre-Law Studies', label: 'Pre-Law Studies' },
  { value: 'Science Teacher Education', label: 'Science Teacher Education' },
  { value: 'Physical Therapy/Therapist', label: 'Physical Therapy/Therapist' },
  { value: 'Music Teacher Education', label: 'Music Teacher Education' },
  { value: 'Communication', label: 'Communication' },
  { value: 'Public Health', label: 'Public Health' },
  { value: 'Geriatrics Nursing', label: 'Geriatrics Nursing' },
  { value: 'HVACR)', label: 'HVACR)' },
  { value: 'Forestry Engineering', label: 'Forestry Engineering' },
  { value: 'Mechanical Engineering Technician', label: 'Mechanical Engineering Technician' },
  { value: 'Optical Engineering', label: 'Optical Engineering' },
  { value: 'Music Technology', label: 'Music Technology' },
  { value: 'Advertising', label: 'Advertising' },
  { value: 'Building Trades', label: 'Building Trades' },
  { value: 'Public Interest Law', label: 'Public Interest Law' },
  { value: 'Woodworking', label: 'Woodworking' },
  { value: 'Precision Production Trades', label: 'Precision Production Trades' },
  { value: 'Library Science and Information Science', label: 'Library Science and Information Science' },
  { value: 'Phlebotomy Technician/Phlebotomist', label: 'Phlebotomy Technician/Phlebotomist' },
  { value: 'Forestry', label: 'Forestry' },
  { value: 'Homeland Security', label: 'Homeland Security' },
  { value: 'Criminology', label: 'Criminology' },
  { value: 'Communications', label: 'Communications' },
  { value: 'International Law', label: 'International Law' },
  { value: 'Liberal Arts and Sciences', label: 'Liberal Arts and Sciences' },
  { value: 'Sales', label: 'Sales' },
  { value: 'Dairy Husbandry and Production', label: 'Dairy Husbandry and Production' },
  { value: 'Humanities/Humanistic Studies', label: 'Humanities/Humanistic Studies' },
  { value: 'Computer and Information Sciences', label: 'Computer and Information Sciences' },
  { value: 'Agriculture Operations', label: 'Agriculture Operations' },
  { value: 'Welding Engineering Technician', label: 'Welding Engineering Technician' },
  { value: 'Anthropology and Sociology', label: 'Anthropology and Sociology' },
  { value: 'Biomedical Technician', label: 'Biomedical Technician' },
  { value: 'Computer Systems Analysis', label: 'Computer Systems Analysis' },
  { value: 'Alternative Medicine and Holistic Health', label: 'Alternative Medicine and Holistic Health' },
  { value: 'Agricultural and Food Products Processing', label: 'Agricultural and Food Products Processing' },
  { value: 'Food and Nutrition', label: 'Food and Nutrition' },
  { value: 'Kinesiology and Physical Therapy', label: 'Kinesiology and Physical Therapy' },
  {
    value: 'Computer and Information Sciences and Support Services',
    label: 'Computer and Information Sciences and Support Services',
  },
  {
    value: 'Ventilation & Refrigeration Maintenance Tech (HAC',
    label: 'Ventilation & Refrigeration Maintenance Tech (HAC',
  },
  { value: 'Physical Sciences', label: 'Physical Sciences' },
  { value: 'Merchandising and Buying Operations', label: 'Merchandising and Buying Operations' },
  { value: 'Fine Arts and Art Studies', label: 'Fine Arts and Art Studies' },
  { value: 'Agriculture', label: 'Agriculture' },
  { value: 'Natural Sciences', label: 'Natural Sciences' },
  { value: 'Restaurant and Food Services Management', label: 'Restaurant and Food Services Management' },
  { value: 'Latin American Studies', label: 'Latin American Studies' },
  { value: 'Drama/Theatre Arts and Stagecraft', label: 'Drama/Theatre Arts and Stagecraft' },
  { value: 'Drama and Dramatics/Theatre Arts', label: 'Drama and Dramatics/Theatre Arts' },
  {
    value: 'Engineering Technologies and Engineering-Related Fields',
    label: 'Engineering Technologies and Engineering-Related Fields',
  },
  { value: 'Political Science and Government', label: 'Political Science and Government' },
  { value: 'Health Professions', label: 'Health Professions' },
  { value: 'Photogrammetry', label: 'Photogrammetry' },
  { value: 'Surveying Technician', label: 'Surveying Technician' },
  { value: 'Pediatrics Nursing', label: 'Pediatrics Nursing' },
  { value: 'Sports Communication', label: 'Sports Communication' },
  { value: 'Middle School Education', label: 'Middle School Education' },
  { value: 'Electronics and Communications Engineering', label: 'Electronics and Communications Engineering' },
  { value: 'Osteopathic Medicine/Osteopathy', label: 'Osteopathic Medicine/Osteopathy' },
  { value: 'Fine and Studio Arts Management', label: 'Fine and Studio Arts Management' },
  { value: 'Health Services/Allied Health/Health Sciences', label: 'Health Services/Allied Health/Health Sciences' },
  {
    value: 'Computer Systems Networking and Telecommunications',
    label: 'Computer Systems Networking and Telecommunications',
  },
  { value: 'MSN)', label: 'MSN)' },
  { value: 'Oncology Nursing', label: 'Oncology Nursing' },
  { value: 'Arabic Language and Literature', label: 'Arabic Language and Literature' },
  { value: 'Film and Photography', label: 'Film and Photography' },
  { value: 'Diesel Mechanics', label: 'Diesel Mechanics' },
  { value: 'Network', label: 'Network' },
  { value: 'Teacher Education', label: 'Teacher Education' },
  { value: 'Dental Assisting/Assistant', label: 'Dental Assisting/Assistant' },
  { value: 'Social Sciences', label: 'Social Sciences' },
  { value: 'Phlebotomy Technician', label: 'Phlebotomy Technician' },
  { value: 'Marketing', label: 'Marketing' },
  { value: 'Business Teacher Education', label: 'Business Teacher Education' },
  { value: 'General Studies and Humanities', label: 'General Studies and Humanities' },
  { value: 'Playwriting and Screenwriting', label: 'Playwriting and Screenwriting' },
  { value: 'Pharmacy Technician', label: 'Pharmacy Technician' },
  { value: 'Norwegian History', label: 'Norwegian History' },
  { value: 'Heavy Equipment Maintenance Technician', label: 'Heavy Equipment Maintenance Technician' },
  { value: 'Public Policy and Social Services', label: 'Public Policy and Social Services' },
  { value: 'Linguistic', label: 'Linguistic' },
  { value: 'Dentistry', label: 'Dentistry' },
  { value: 'Bioinformatics', label: 'Bioinformatics' },
  { value: 'Elementary Education and Teaching', label: 'Elementary Education and Teaching' },
  { value: 'Computer Science', label: 'Computer Science' },
  {
    value: 'Russian and Eastern European Languages and Literature',
    label: 'Russian and Eastern European Languages and Literature',
  },
  { value: 'Utilities', label: 'Utilities' },
  { value: 'Mental and Social Health Services', label: 'Mental and Social Health Services' },
  { value: 'Engineering Technicians', label: 'Engineering Technicians' },
  { value: 'Studio Arts', label: 'Studio Arts' },
  { value: 'Pre-Veterinary Studies', label: 'Pre-Veterinary Studies' },
  { value: 'Related Services', label: 'Related Services' },
  { value: 'Dental Studies', label: 'Dental Studies' },
  { value: 'Geospatial Information Technology', label: 'Geospatial Information Technology' },
  { value: 'Chemistry', label: 'Chemistry' },
  { value: 'Weaving', label: 'Weaving' },
  { value: 'Quality Control Technician', label: 'Quality Control Technician' },
  { value: 'Game Design and Interactive Media', label: 'Game Design and Interactive Media' },
  { value: 'Elementary Education', label: 'Elementary Education' },
  { value: 'Dairy Science', label: 'Dairy Science' },
  { value: 'Teaching', label: 'Teaching' },
  { value: 'History', label: 'History' },
  { value: 'Cosmetology/Cosmetologist', label: 'Cosmetology/Cosmetologist' },
  {
    value: 'Electrical and Power Transmission Installation/Installer',
    label: 'Electrical and Power Transmission Installation/Installer',
  },
  { value: 'Family and Consumer Sciences/Human Sciences', label: 'Family and Consumer Sciences/Human Sciences' },
  { value: 'Aerospace', label: 'Aerospace' },
  { value: 'Indonesian Language', label: 'Indonesian Language' },
  { value: 'Trades and Personal Services', label: 'Trades and Personal Services' },
  { value: 'Welding', label: 'Welding' },
  { value: 'Scottish Studies', label: 'Scottish Studies' },
  { value: 'Legal Support Services', label: 'Legal Support Services' },
  { value: 'Publishing', label: 'Publishing' },
  { value: 'Interdisciplinary Studies', label: 'Interdisciplinary Studies' },
  { value: 'Fire Protection', label: 'Fire Protection' },
  { value: 'Business and Management', label: 'Business and Management' },
  { value: 'Antitrust and Trade Regulation', label: 'Antitrust and Trade Regulation' },
  { value: 'Gifted and Talented Education', label: 'Gifted and Talented Education' },
  { value: 'Economics', label: 'Economics' },
  { value: 'Fire Science/Fire-fighting', label: 'Fire Science/Fire-fighting' },
  { value: 'Linguistics', label: 'Linguistics' },
  { value: 'Design and Visual Communications', label: 'Design and Visual Communications' },
  { value: 'Media Management', label: 'Media Management' },
  { value: 'Imaging Science', label: 'Imaging Science' },
  { value: 'Aquaculture', label: 'Aquaculture' },
  { value: 'Special Education and Teaching', label: 'Special Education and Teaching' },
  { value: 'Agronomy', label: 'Agronomy' },
  { value: 'Adolescents', label: 'Adolescents' },
  { value: 'Political Science', label: 'Political Science' },
  {
    value: 'Automobile/Automotive Mechanics Technology/Technician',
    label: 'Automobile/Automotive Mechanics Technology/Technician',
  },
  { value: 'Housing', label: 'Housing' },
  { value: 'Machine and Metal Working', label: 'Machine and Metal Working' },
  {
    value: 'Manufacturing Engineering Technology/Technician',
    label: 'Manufacturing Engineering Technology/Technician',
  },
  { value: 'Web', label: 'Web' },
  { value: 'Respiratory Care Therapy', label: 'Respiratory Care Therapy' },
  { value: 'Punjabi Language', label: 'Punjabi Language' },
  { value: 'Italian Studies', label: 'Italian Studies' },
  { value: 'Welding Technology/Welder', label: 'Welding Technology/Welder' },
  { value: 'Master of Divinity', label: 'Master of Divinity' },
  { value: 'Physical Therapy', label: 'Physical Therapy' },
  { value: 'Athletics', label: 'Athletics' },
  { value: 'Musical Instruments', label: 'Musical Instruments' },
  { value: 'Chiropractic', label: 'Chiropractic' },
  { value: 'Music', label: 'Music' },
  { value: 'Educational Administration', label: 'Educational Administration' },
  { value: 'Social Work', label: 'Social Work' },
  {
    value: 'Clinical/Medical Laboratory Science/Research and Allied Professions',
    label: 'Clinical/Medical Laboratory Science/Research and Allied Professions',
  },
  { value: 'International Relations and Affairs', label: 'International Relations and Affairs' },
  { value: 'Military History', label: 'Military History' },
  { value: 'Medical Records Technician', label: 'Medical Records Technician' },
  { value: 'Engineering', label: 'Engineering' },
  { value: 'Disability Law', label: 'Disability Law' },
  { value: 'Atmospheric Sciences and Meteorology', label: 'Atmospheric Sciences and Meteorology' },
  { value: 'Spanish Studies', label: 'Spanish Studies' },
  { value: 'International Studies', label: 'International Studies' },
  { value: 'Agricultural Mechanization', label: 'Agricultural Mechanization' },
  { value: 'Safety Studies', label: 'Safety Studies' },
  { value: 'Robotics and Automation Engineering', label: 'Robotics and Automation Engineering' },
  { value: 'Visual and Performing Arts', label: 'Visual and Performing Arts' },
  { value: 'Greek Language', label: 'Greek Language' },
  { value: 'Ceramic Engineering', label: 'Ceramic Engineering' },
  { value: 'Long-Term Care Nursing', label: 'Long-Term Care Nursing' },
  { value: 'Natural History', label: 'Natural History' },
  { value: 'Aged and Aging', label: 'Aged and Aging' },
  { value: 'Foreign Languages and Literatures', label: 'Foreign Languages and Literatures' },
  { value: 'Film/Video and Photographic Arts', label: 'Film/Video and Photographic Arts' },
  { value: 'Jewish History', label: 'Jewish History' },
  { value: 'Golf Industry', label: 'Golf Industry' },
  { value: 'Geology/Earth Science', label: 'Geology/Earth Science' },
  { value: 'Management Sciences and Information Systems', label: 'Management Sciences and Information Systems' },
  { value: 'Automotive Engineering Technology/Technician', label: 'Automotive Engineering Technology/Technician' },
  { value: 'Sewing', label: 'Sewing' },
  { value: 'Beer and Wine Industries', label: 'Beer and Wine Industries' },
  { value: 'Surveying Engineering', label: 'Surveying Engineering' },
  { value: 'Materials Engineering', label: 'Materials Engineering' },
  { value: 'Theatre/Theater', label: 'Theatre/Theater' },
  { value: 'Horticultural Science', label: 'Horticultural Science' },
  { value: 'Dance', label: 'Dance' },
  { value: 'Furniture Design and Manufacturing', label: 'Furniture Design and Manufacturing' },
  { value: 'Theological and Ministerial Studies', label: 'Theological and Ministerial Studies' },
  { value: 'Mining and Petroleum Engineering', label: 'Mining and Petroleum Engineering' },
  { value: 'Folklore', label: 'Folklore' },
  { value: 'Geological and Earth Sciences/Geosciences', label: 'Geological and Earth Sciences/Geosciences' },
  { value: 'Welding Engineering', label: 'Welding Engineering' },
  { value: 'Environmental Engineering Technician', label: 'Environmental Engineering Technician' },
  { value: 'Visual Impairments', label: 'Visual Impairments' },
  { value: 'Science Technologies/Technicians', label: 'Science Technologies/Technicians' },
  { value: 'Industrial and Product Design', label: 'Industrial and Product Design' },
  { value: 'Classical Studies', label: 'Classical Studies' },
  { value: 'American History', label: 'American History' },
  { value: 'Communications Engineering', label: 'Communications Engineering' },
  { value: 'Music Management', label: 'Music Management' },
  { value: 'Personal and Culinary Services', label: 'Personal and Culinary Services' },
  { value: 'Natural Resources', label: 'Natural Resources' },
  { value: 'Finnish Studies', label: 'Finnish Studies' },
  { value: 'Naval Architecture', label: 'Naval Architecture' },
  { value: 'Art Therapy', label: 'Art Therapy' },
  { value: 'Cinematography and Video Production', label: 'Cinematography and Video Production' },
  { value: 'Developmental and Child Psychology', label: 'Developmental and Child Psychology' },
  { value: 'Legal Administrative Assistant', label: 'Legal Administrative Assistant' },
  { value: 'Pre-Medicine/Pre-Medical Studies', label: 'Pre-Medicine/Pre-Medical Studies' },
  { value: 'Architecture', label: 'Architecture' },
  { value: 'Palliative Care Nursing', label: 'Palliative Care Nursing' },
  { value: 'Criminal Law', label: 'Criminal Law' },
  { value: 'Agricultural Aviation', label: 'Agricultural Aviation' },
  {
    value: 'Education/Teaching of Individuals in Early Childhood Special Education Programs',
    label: 'Education/Teaching of Individuals in Early Childhood Special Education Programs',
  },
  { value: 'Construction Law', label: 'Construction Law' },
  { value: 'Crisis/Emergency/Disaster Management', label: 'Crisis/Emergency/Disaster Management' },
  { value: 'Liberal Arts and Humanities', label: 'Liberal Arts and Humanities' },
  { value: 'Drug Use and Abuse', label: 'Drug Use and Abuse' },
  { value: 'Heating Engineering', label: 'Heating Engineering' },
  { value: 'Packaging', label: 'Packaging' },
  { value: 'Bus', label: 'Bus' },
  { value: 'Operations Research', label: 'Operations Research' },
  { value: 'Interior Design', label: 'Interior Design' },
  { value: 'Turkmen Language', label: 'Turkmen Language' },
  { value: 'Metal and Jewelry Arts', label: 'Metal and Jewelry Arts' },
  { value: 'Hearing Impairments', label: 'Hearing Impairments' },
  { value: 'Dietetics and Clinical Nutrition', label: 'Dietetics and Clinical Nutrition' },
  { value: 'Psychology', label: 'Psychology' },
  { value: 'Turfgrass Science', label: 'Turfgrass Science' },
  { value: 'Finance and Accounting', label: 'Finance and Accounting' },
  { value: 'Plumbing Technology/Plumber', label: 'Plumbing Technology/Plumber' },
  { value: 'Energy', label: 'Energy' },
  { value: 'Early Childhood Education and Teaching', label: 'Early Childhood Education and Teaching' },
  { value: 'Education & Teaching', label: 'Education & Teaching' },
  { value: 'Leadership and Operational Art', label: 'Leadership and Operational Art' },
  { value: 'Biology', label: 'Biology' },
  { value: 'Mobil Crane Operation/Operator', label: 'Mobil Crane Operation/Operator' },
  { value: 'Dental Hygiene', label: 'Dental Hygiene' },
  { value: 'Kindergarten and Preschool Education', label: 'Kindergarten and Preschool Education' },
  {
    value: 'Mental and Social Health Services and Allied Professions',
    label: 'Mental and Social Health Services and Allied Professions',
  },
  { value: 'Science', label: 'Science' },
  { value: 'Marine Biology and Oceanography', label: 'Marine Biology and Oceanography' },
  { value: 'Agricultural Engineering', label: 'Agricultural Engineering' },
  { value: 'Fashion/Apparel Design', label: 'Fashion/Apparel Design' },
  { value: 'Law and Legal Studies', label: 'Law and Legal Studies' },
  { value: 'Plant Nursery Operations and Management', label: 'Plant Nursery Operations and Management' },
  { value: 'HVAC and Refrigeration Engineering Technician', label: 'HVAC and Refrigeration Engineering Technician' },
  { value: 'Veterinary Studies', label: 'Veterinary Studies' },
  { value: 'Mental Health Counseling', label: 'Mental Health Counseling' },
  { value: 'Animal Sciences and Husbandry', label: 'Animal Sciences and Husbandry' },
  {
    value: 'Criminal Justice/Law Enforcement Administration',
    label: 'Criminal Justice/Law Enforcement Administration',
  },
  { value: 'Somali Language', label: 'Somali Language' },
  { value: 'Farsi Language', label: 'Farsi Language' },
  { value: 'Biophysics', label: 'Biophysics' },
  { value: 'Culinary Arts', label: 'Culinary Arts' },
  { value: 'Environmental Studies', label: 'Environmental Studies' },
  { value: 'Wood Industry', label: 'Wood Industry' },
  { value: 'Public Administration', label: 'Public Administration' },
  { value: 'Business/Commerce', label: 'Business/Commerce' },
  { value: 'Plumbing and Related Water Supply Services', label: 'Plumbing and Related Water Supply Services' },
  { value: 'Social Work and Youth Services', label: 'Social Work and Youth Services' },
  { value: 'Constitutional Law', label: 'Constitutional Law' },
  { value: 'Occupational Health Nursing', label: 'Occupational Health Nursing' },
  { value: 'Animal/Livestock Husbandry and Production', label: 'Animal/Livestock Husbandry and Production' },
  { value: 'Paper Industry', label: 'Paper Industry' },
  { value: 'Family and Consumer Studies Education', label: 'Family and Consumer Studies Education' },
  { value: 'Language & Foreign Languages', label: 'Language & Foreign Languages' },
  { value: 'Medicine', label: 'Medicine' },
  { value: 'Veterinary Sciences', label: 'Veterinary Sciences' },
  { value: 'Law Education', label: 'Law Education' },
  { value: 'Environmental Engineering', label: 'Environmental Engineering' },
  { value: 'Technology Education', label: 'Technology Education' },
  { value: 'Dancing', label: 'Dancing' },
  { value: 'Pre-Nursing Studies', label: 'Pre-Nursing Studies' },
  { value: 'Medical Insurance Billing and Claims', label: 'Medical Insurance Billing and Claims' },
  { value: 'Early Childhood Education', label: 'Early Childhood Education' },
  { value: 'Mechanical Engineering', label: 'Mechanical Engineering' },
  { value: 'Building Construction Technology', label: 'Building Construction Technology' },
  { value: 'Database', label: 'Database' },
  { value: 'Resource Management', label: 'Resource Management' },
  { value: 'Fire Protection and Prevention', label: 'Fire Protection and Prevention' },
  {
    value: 'Family and Consumer Sciences/Home Economics Teacher Education',
    label: 'Family and Consumer Sciences/Home Economics Teacher Education',
  },
  { value: 'Sports Management and Leisure Studies', label: 'Sports Management and Leisure Studies' },
  { value: 'Health Aides and Attendants', label: 'Health Aides and Attendants' },
  { value: 'Pharmacy', label: 'Pharmacy' },
  { value: 'Historical Preservation', label: 'Historical Preservation' },
  { value: 'Photojournalism', label: 'Photojournalism' },
  { value: 'Nurse Midwifery', label: 'Nurse Midwifery' },
  { value: 'Applied Communication', label: 'Applied Communication' },
  { value: 'Day Care', label: 'Day Care' },
  { value: 'Operations Management', label: 'Operations Management' },
  { value: 'Health/Medical Preparatory Programs', label: 'Health/Medical Preparatory Programs' },
  { value: 'Textile Engineering', label: 'Textile Engineering' },
  { value: 'Truck', label: 'Truck' },
  { value: 'Anatomy', label: 'Anatomy' },
  { value: 'Firefighting and Related Protective Services', label: 'Firefighting and Related Protective Services' },
  { value: 'Agricultural Sciences', label: 'Agricultural Sciences' },
  { value: 'Cosmetology', label: 'Cosmetology' },
  { value: 'Paralegal', label: 'Paralegal' },
  { value: 'Information Technology', label: 'Information Technology' },
  { value: 'Recording Arts Technician', label: 'Recording Arts Technician' },
  { value: 'Agricultural Business and Management', label: 'Agricultural Business and Management' },
  { value: 'Art Teacher Education', label: 'Art Teacher Education' },
  { value: 'Homeland Security and Disaster Management', label: 'Homeland Security and Disaster Management' },
  { value: 'Medical Insurance Coding', label: 'Medical Insurance Coding' },
  { value: 'Dental Assisting', label: 'Dental Assisting' },
  { value: 'Behavioral Sciences', label: 'Behavioral Sciences' },
  { value: 'Mechanics and Repairers', label: 'Mechanics and Repairers' },
  { value: 'Wellness Studies', label: 'Wellness Studies' },
  { value: 'Aging', label: 'Aging' },
  { value: 'Agricultural Production Operations', label: 'Agricultural Production Operations' },
  { value: 'Nursing', label: 'Nursing' },
  { value: 'Radiologic Technician', label: 'Radiologic Technician' },
  { value: 'Religion/Religious Studies', label: 'Religion/Religious Studies' },
  { value: 'Human Resources', label: 'Human Resources' },
  { value: 'Electrical and Electronics Engineering', label: 'Electrical and Electronics Engineering' },
  { value: 'Computer Engineering Technician', label: 'Computer Engineering Technician' },
  { value: 'Refrigerating and Air Conditioning Engineering', label: 'Refrigerating and Air Conditioning Engineering' },
  { value: 'Interior Architecture', label: 'Interior Architecture' },
  { value: 'Actuarial Science', label: 'Actuarial Science' },
  { value: 'Ministry', label: 'Ministry' },
  { value: 'Lighting', label: 'Lighting' },
  { value: 'Fashion and Apparel Design', label: 'Fashion and Apparel Design' },
  { value: 'Veterinary Medicine', label: 'Veterinary Medicine' },
  { value: 'Digital Communication and Media/Multimedia', label: 'Digital Communication and Media/Multimedia' },
  { value: 'Acting', label: 'Acting' },
  { value: 'Family Relations', label: 'Family Relations' },
  { value: 'Chinese Language and Literature', label: 'Chinese Language and Literature' },
  { value: 'Security Services', label: 'Security Services' },
  { value: 'Mathematics and Statistics', label: 'Mathematics and Statistics' },
  { value: 'Agricultural Business', label: 'Agricultural Business' },
  { value: 'Cancer', label: 'Cancer' },
  { value: 'Community and Regional Planning', label: 'Community and Regional Planning' },
  { value: 'Athletic Training', label: 'Athletic Training' },
  { value: 'Historic Preservation and Conservation', label: 'Historic Preservation and Conservation' },
  { value: 'Human Development', label: 'Human Development' },
  { value: 'Commercial and Advertising Art', label: 'Commercial and Advertising Art' },
  { value: 'Family Studies', label: 'Family Studies' },
  { value: 'Genetics', label: 'Genetics' },
  { value: 'Critical Care Nursing', label: 'Critical Care Nursing' },
  { value: 'Library Science', label: 'Library Science' },
  { value: 'Pre-Pharmacy Studies', label: 'Pre-Pharmacy Studies' },
  { value: 'Literature', label: 'Literature' },
  { value: 'Child Development', label: 'Child Development' },
  { value: 'Pharmacology and Toxicology', label: 'Pharmacology and Toxicology' },
  { value: 'Computer Software Engineering', label: 'Computer Software Engineering' },
  { value: 'Global & Cultural Studies', label: 'Global & Cultural Studies' },
  { value: 'Entertainment Law', label: 'Entertainment Law' },
  { value: 'General', label: 'General' },
  { value: 'Danish Language', label: 'Danish Language' },
  { value: 'Transportation and Highway Engineering', label: 'Transportation and Highway Engineering' },
  { value: 'Emergency Medical Technician', label: 'Emergency Medical Technician' },
  { value: 'Foreign Language Teacher Education', label: 'Foreign Language Teacher Education' },
  { value: 'Ground Transportation', label: 'Ground Transportation' },
  {
    value: 'Public Administration and Social Service Professions',
    label: 'Public Administration and Social Service Professions',
  },
  { value: 'System Administration', label: 'System Administration' },
  { value: 'Related Sciences', label: 'Related Sciences' },
  { value: 'Communication and Media Studies', label: 'Communication and Media Studies' },
  { value: 'Healthcare', label: 'Healthcare' },
  { value: 'Theatre Design', label: 'Theatre Design' },
  { value: 'Foods', label: 'Foods' },
  { value: 'Italian Language and Literature', label: 'Italian Language and Literature' },
  { value: 'Carpentry/Carpenter', label: 'Carpentry/Carpenter' },
  { value: 'Related Language Studies and Services', label: 'Related Language Studies and Services' },
  { value: 'Cartography', label: 'Cartography' },
  { value: 'Shipping Industry', label: 'Shipping Industry' },
  { value: 'Manufacturing Technology', label: 'Manufacturing Technology' },
  { value: 'Natural Resources Conservation and Management', label: 'Natural Resources Conservation and Management' },
  { value: 'Cartoonists and Cartoons', label: 'Cartoonists and Cartoons' },
  { value: 'Computer Hardware Engineering', label: 'Computer Hardware Engineering' },
  { value: 'Creative Writing', label: 'Creative Writing' },
  { value: 'Family Studies and Consumer Sciences', label: 'Family Studies and Consumer Sciences' },
  {
    value: 'Nurse/Nursing Assistant/Aide and Patient Care Assistant',
    label: 'Nurse/Nursing Assistant/Aide and Patient Care Assistant',
  },
  { value: 'Motorcycle Maintenance and Repair', label: 'Motorcycle Maintenance and Repair' },
  { value: 'Pest Management', label: 'Pest Management' },
  { value: 'Industrial Electronics Technician', label: 'Industrial Electronics Technician' },
  { value: 'Physiology and Pathology', label: 'Physiology and Pathology' },
  { value: 'Air Conditioning', label: 'Air Conditioning' },
  { value: 'Biology/Biological Sciences', label: 'Biology/Biological Sciences' },
  { value: 'Related Support Services', label: 'Related Support Services' },
  { value: 'Graphic Communications', label: 'Graphic Communications' },
  { value: 'Pre-Dentistry Studies', label: 'Pre-Dentistry Studies' },
  { value: 'Rehabilitation and Therapeutic Professions', label: 'Rehabilitation and Therapeutic Professions' },
  { value: 'Occupational Safety and Health Technician', label: 'Occupational Safety and Health Technician' },
  { value: 'Nutrition', label: 'Nutrition' },
  { value: 'Military Science', label: 'Military Science' },
  { value: 'International Business', label: 'International Business' },
  { value: 'Aeronautical and Astronautical Engineering', label: 'Aeronautical and Astronautical Engineering' },
  { value: 'Aircraft Maintenance', label: 'Aircraft Maintenance' },
  { value: 'Court Reporting/Court Reporter', label: 'Court Reporting/Court Reporter' },
  { value: 'Geology and Earth Science', label: 'Geology and Earth Science' },
  { value: 'Medical Assistant', label: 'Medical Assistant' },
  { value: 'Heating', label: 'Heating' },
  { value: 'Danish Studies', label: 'Danish Studies' },
  { value: 'Retail Management', label: 'Retail Management' },
  { value: 'Japanese Studies', label: 'Japanese Studies' },
  { value: 'Nursing Administration', label: 'Nursing Administration' },
  { value: 'Exercise Physiology', label: 'Exercise Physiology' },
  { value: 'Health Teacher Education', label: 'Health Teacher Education' },
  { value: 'Information Science', label: 'Information Science' },
  { value: 'Nanoscience', label: 'Nanoscience' },
  { value: 'Arts and Crafts', label: 'Arts and Crafts' },
  { value: 'International/Global Studies', label: 'International/Global Studies' },
  { value: 'Drama and Theatre Production', label: 'Drama and Theatre Production' },
  { value: 'Occupational Therapist Assistant', label: 'Occupational Therapist Assistant' },
  { value: 'User Experience Design', label: 'User Experience Design' },
  { value: 'Divinity/Ministry', label: 'Divinity/Ministry' },
  { value: 'Composers and Compositions', label: 'Composers and Compositions' },
  { value: 'Massage Therapy and Bodywork', label: 'Massage Therapy and Bodywork' },
  { value: 'Manufacturing Engineering Technician', label: 'Manufacturing Engineering Technician' },
  { value: 'International Relations', label: 'International Relations' },
  { value: 'Construction Trades', label: 'Construction Trades' },
  { value: 'Nursing Assistant', label: 'Nursing Assistant' },
  { value: 'Design and Applied Arts', label: 'Design and Applied Arts' },
  { value: 'Audiovisual Materials and Equipment', label: 'Audiovisual Materials and Equipment' },
  { value: 'Business', label: 'Business' },
  { value: 'Fine Arts', label: 'Fine Arts' },
  { value: 'Emergency Care Attendant (EMT Ambulance)', label: 'Emergency Care Attendant (EMT Ambulance)' },
  { value: 'Ecology and Evolutionary Biology', label: 'Ecology and Evolutionary Biology' },
  { value: 'Scottish History', label: 'Scottish History' },
  { value: 'Geological Engineering', label: 'Geological Engineering' },
  { value: 'Plumbing', label: 'Plumbing' },
  { value: 'Health Care Management', label: 'Health Care Management' },
  { value: 'Brass Instruments', label: 'Brass Instruments' },
  { value: 'Operating Room Nursing', label: 'Operating Room Nursing' },
  { value: 'English and Speech Teacher Education', label: 'English and Speech Teacher Education' },
  { value: 'Botany and Plant Physiology', label: 'Botany and Plant Physiology' },
  { value: 'Multiple Levels', label: 'Multiple Levels' },
  { value: 'Intelligence', label: 'Intelligence' },
  { value: 'Legal Studies', label: 'Legal Studies' },
  { value: 'Aeronautics and Aviation Technology', label: 'Aeronautics and Aviation Technology' },
  { value: 'Lung and Respiratory Disease Nursing', label: 'Lung and Respiratory Disease Nursing' },
  { value: 'Missionary Studies', label: 'Missionary Studies' },
  { value: 'Pre-Medicine Studies', label: 'Pre-Medicine Studies' },
  { value: 'Turkish Language', label: 'Turkish Language' },
  { value: 'Culinary Arts and Food Service', label: 'Culinary Arts and Food Service' },
  { value: 'Religious Education', label: 'Religious Education' },
  { value: 'Education', label: 'Education' },
].sort((a, b) => a.label.localeCompare(b.label));
