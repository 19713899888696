import { IGetAllScholarships, IScholarship } from 'types/scholarships';
import { HttpService } from './http';

class ScholarshipService extends HttpService {
  apiBaseUrl: any;
  constructor(apiBaseUrl: string) {
    super();
    this.apiBaseUrl = apiBaseUrl;
  }

  async getScholarships(data: Record<string, string>) {
    try {
      const queryParams = new URLSearchParams(data).toString();

      const response: APIResponse<IGetAllScholarships> = await this.get(
        `${this.apiBaseUrl}/scholarships/?${queryParams}`
      );

      return response;
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error fetching scholarships:', error);
      } else {
        console.error('Unexpected error:', error);
      }

      throw error;
    }
  }

  async getScholarshipById(id?: number) {
    try {
      const response: APIResponse<IScholarship> = await this.get(`${this.apiBaseUrl}/scholarships/${id}`);

      return response;
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error fetching scholarship  by Id:', error);
      } else {
        console.error('Unexpected error:', error);
      }

      throw error;
    }
  }
}

const scholarshipService = new ScholarshipService(`${process.env.REACT_APP_BASE_URL}`);
export default scholarshipService;
