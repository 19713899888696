import {
  Box,
  Button,
  Grid,
  GridItem,
  Hide,
  HStack,
  Skeleton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AnalyticsScreenName, Routes } from '../../../Constants/constants';
import useMixpanel from '../../../lib/Analytics/Mixpanel/useMixpanel';
import BreadcrumbNav from '../../../lib/UI-Components/BreadcrumNav';
import StyledCard from '../../../lib/UI-Components/StyledCards';
import { useGetScholarship } from './../../../Hooks/Scholarships/useScholarships';
import Application from './Application';
import Eligibility from './Eligibility';
import Overview from './Overview';
import SidePanel from './SidePanel';

const ScholarshipDetails = () => {
  const { id } = useParams();
  const { data, isFetching } = useGetScholarship(id ? parseInt(id, 10) : undefined);
  const navigate = useNavigate();
  const { trackEvent } = useMixpanel(AnalyticsScreenName.ScreenScholarshipDetail);

  return (
    <Grid
      templateColumns={['1fr', '1fr', '3fr 1fr']}
      w='100%'
      h='100%'
      gap={6}
      px={6}
      py={4}
      height={'calc(100vh - 9vh)'}
    >
      <GridItem height={'full'}>
        <StyledCard>
          <VStack width={'100%'} height={'full'} justifyContent={'flex-start'} alignItems={'flex-start'}>
            <BreadcrumbNav
              items={[
                { label: 'Scholarships', href: Routes.Scholarships },
                { label: data?.name || '', href: '' },
              ]}
            />
            <HStack width={'100%'} justifyContent={'space-between'}>
              {isFetching ? (
                <Skeleton borderRadius='12px' height={'5vh'} width={'70%'} startColor='gray2' endColor='gray1' />
              ) : (
                <Text
                  fontSize={'24px'}
                  fontStyle={'normal'}
                  fontWeight={600}
                  lineHeight={'normal'}
                  color={'black1'}
                  width={'80%'}
                  letterSpacing={'-0.48px'}
                >
                  {data?.name}
                </Text>
              )}
              {!(data?.requires_essay === false) && !isFetching && (
                <Button
                  variant={'ghost'}
                  onClick={() => {
                    trackEvent('Start Writing Tapped - Scholarship');
                    navigate(Routes.Essays);
                  }}
                >
                  Start Writing
                </Button>
              )}
            </HStack>
            <VStack justifyContent={'flex-start'} alignItems={'flex-start'}>
              <Box width='100%'>
                <Tabs variant='custom' align='start' width='100%'>
                  <TabList gap={[1, 1, 10, 10]} width={'100%'}>
                    <Tab textAlign={['center', 'left']}>
                      <Text
                        textAlign={'center'}
                        color='#8C8CA1'
                        fontSize={['12px', '14px']}
                        fontStyle={'normal'}
                        fontWeight={500}
                        lineHeight={'140%'}
                      >
                        Overview
                      </Text>
                    </Tab>
                    <Tab textAlign='left'>
                      <Text
                        textAlign={'center'}
                        color='#8C8CA1'
                        fontSize={['12px', '14px']}
                        fontStyle={'normal'}
                        fontWeight={500}
                        lineHeight={'140%'}
                      >
                        Eligibility
                      </Text>
                    </Tab>
                    <Tab textAlign='left'>
                      <Text
                        textAlign={'center'}
                        color='#8C8CA1'
                        fontSize={['12px', '14px']}
                        fontStyle={'normal'}
                        fontWeight={500}
                        lineHeight={'140%'}
                      >
                        Application
                      </Text>
                    </Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel width={'full'}>
                      {isFetching && (
                        <Box width={['100%', '100%', '100%', '680px']}>
                          <Skeleton
                            borderRadius='12px'
                            height={'35vh'}
                            width={'100%'}
                            startColor='gray2'
                            endColor='gray1'
                          />
                        </Box>
                      )}
                      {data && !isFetching && <Overview data={data} />}
                    </TabPanel>
                    <TabPanel>{data && <Eligibility data={data} />}</TabPanel>
                    <TabPanel>{data && <Application data={data} />}</TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </VStack>
          </VStack>
        </StyledCard>
      </GridItem>
      <Hide below='md'>
        <GridItem>
          {isFetching && (
            <Box width={'100%'}>
              <Skeleton borderRadius='12px' height={'40vh'} width={'100%'} startColor='gray2' endColor='gray1' />
            </Box>
          )}
          {data && !isFetching && <SidePanel data={data} />}
        </GridItem>
      </Hide>
    </Grid>
  );
};

export default ScholarshipDetails;
