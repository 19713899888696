export enum BASIC_VERSION_LIMITS {
  COLLEGES = 50,
  ACTIVITIES = 50,
  WRITING_TOOLS_PER_HR = 200,
  ESSAY_REVIEWS_PER_HR = 200,
}

export enum PREMIUMVERSIONLIMITS {
  COLLEGES = 1000,
  ACTIVITIES = 1000,
  WRITING_TOOLS_PER_HR = 100,
  ESSAY_REVIEWS_PER_HR = 50,
}

export enum LIMITTYPES {
  COLLEGES = 'COLLEGES',
  ACTIVITIES = 'ACTIVITIES',
  WRITING_TOOLS_PER_HR = 'WRITINGTOOLSPERHR',
  ESSAY_REVIEWS_PER_HR = 'ESSAYREVIEWSPERHR',
}

export const EDIT_PROFILE_FIELDS = [
  'country',
  'income',
  'ethnicity',
  'gender',
  'gpa',
  'satmath',
  'satenglish',
  'act',
  'highschool',
  'classrank',
  'gradyear',
];

export const INCOME_LEVELS = [
  '<$50,000',
  '$50,000-$100,000',
  '$100,000-$150,000',
  '$150,000-$200,000',
  '$200,000-$250,000',
  '$250,000+',
];

export const ETHNICITIES = [
  'East Asian',
  'South Asian',
  'White',
  'Hispanic',
  'Black',
  'Native American or Pacific Islander',
  'Other',
];

export const GENDERS = ['Male', 'Female', 'Other'];

export const REWORD_TOOL_STYLES = ['Default', 'Academic', 'Casual', 'Serious'];

export enum HEADER_LINKS_KOLLEGIO {
  Home = 'Home',
  Essays = 'Essays',
  Colleges = 'Colleges',
  Activities = 'Activities',
  Planner = 'Planner',
  Scholarships = 'Scholarships',
}

export enum CALENDAR_EVENT_TYPES {
  CollegeApplicationDeadline = 'College Application Deadline',
  CounselorMeeting = 'Counselor Meeting',
  Exam = 'Exam',
  Task = 'Task',
  Other = 'Other',
}

export enum TASK_OPTIONS {
  NOTCOMPLETED = 'Not Completed',
  COMPLETED = 'Completed',
}

export enum HEADER_PROFILE {
  ApplicationProfile = 'Application Profile',
  ApplicationBackground = 'Applicant Background',
  ChangePassword = 'Change Password',
}

export enum HEADER_ESSAY_SELECTION {
  NewEssays = 'New Essays',
  SavedEssays = 'Saved Essays',
}

export enum ACTIVITIY_VIEW {
  ACTIVITY_SELECTOR = 'Activity Selector',
  ACTIVITY_MANAGER = 'Activity Manager',
}

export enum HEADER_ACTIVITIES {
  Overview = 'Overview',
  ActivitiesList = 'Activity List',
}

export enum AnalyticsScreenName {
  ScreenLogin = 'screenLogin',
  ScreenHome = 'screenHome',
  ScreenColleges = 'screenColleges',
  ScreenCollegeDetails = 'screenCollegeDetails',
  ScreenEssays = 'screenEssays',
  ScreenEssaySelection = 'screenEssaySelection',
  ScreenEssayEditor = 'screenEssayEditor',
  ScreenActivities = 'screenActivities',
  ScreenActivitiesSelector = 'screenActivitiesSelector',
  ScreenProfile = 'screenProfile',
  ScreenResources = 'screenResources',
  ScreenGeneratePlan = 'screenGeneratePlan',
  ScreenPlanner = 'screenPlanner',
  ScreenVerify = 'screenVerify',
  ScreenConfirm = 'screenConfirm',
  ScreenJoin = 'screenJoin',
  ScreenForgot = 'screenForgot',
  ScreenReset = 'screenReset',
  ScreenNotes = 'screenNotes',
  ScreenHeader = 'screenHeader',
  ScreenGetStarted = 'screenGetStarted',
  ScreenQuestionnaire = 'screenQuestionnaire',
  ScreenFirstUX = 'screenFirstUX',
  ScreenRecommender = 'screenRecommender',
  ScreenRecommendations = 'screenRecommendations',
  ScreenEssayReviewer = 'screenEssayReviewer',
  ScreenCopilot = 'screenUploadBragsheet',
  ScreenScholarships = 'screenScholarships',
  ScreenScholarshipDetail = 'screenScholarshipDetail',
}

export enum Routes {
  Login = '/login',
  Default = '/',
  Home = '/home',
  Colleges = '/colleges',
  CollegeDetails = '/college/:id',
  Essays = '/essays',
  EssayWriter = '/essays/edit/:id',
  EssayReviewer = '/essays/view/:id',
  Activities = '/activities',
  AddActivity = '/activities/add',
  EditActivity = '/activities/edit/:id',
  Profile = '/profile',
  Resources = '/resources',
  GeneratePlan = '/planner',
  Plan = '/planner',
  Verify = '/verify',
  Confirm = '/confirm',
  Join = '/join',
  Forgot = '/forgot',
  Reset = '/reset',
  Questionnaire = '/questionnaire',
  FirstTimeUX = '/intro',
  ActivityUploadReview = '/activities/upload',
  Recommender = '/recommender',
  Recommendations = '/recommendations',
  RecommendationsExpanded = '/recommendations/:id',
  ChanceMe = '/colleges/chance-me',
  SearchUniveristy = '/colleges/chance-me/search',
  AllUniversity = '/colleges/chance-me/all',
  SingleUniversity = '/colleges/chance-me/:id',
  Scholarships = '/scholarships',
  ScholarshipDetails = '/scholarships/details/:id',
  BragSheet = '/brag/:id',
  Explore = '/explore',
}

export const PublicRoutes = [
  Routes.Default,
  Routes.Login,
  Routes.Join,
  Routes.Forgot,
  Routes.Reset,
  Routes.Questionnaire,
  Routes.Verify,
  Routes.EssayReviewer,
];

export const PrivateRoutes = [
  Routes.EssayWriter,
  Routes.Essays,
  Routes.Home,
  Routes.Profile,
  Routes.Activities,
  Routes.AddActivity,
  Routes.Colleges,
  Routes.Plan,
  Routes.ActivityUploadReview,
  Routes.Recommendations,
  Routes.RecommendationsExpanded,
  Routes.Recommender,
  Routes.FirstTimeUX,
  Routes.CollegeDetails,
  Routes.Resources,
  Routes.EditActivity,
  Routes.SearchUniveristy,
  Routes.ChanceMe,
  Routes.AllUniversity,
  Routes.SingleUniversity,
  Routes.Scholarships,
  Routes.ScholarshipDetails,
  Routes.BragSheet,
  Routes.Explore,
];

export const getScreenNameFromRoute = (route: string): AnalyticsScreenName | undefined => {
  switch (route) {
    case Routes.Login:
      return AnalyticsScreenName.ScreenLogin;
    case Routes.Home:
      return AnalyticsScreenName.ScreenHome;
    case Routes.Default:
      return AnalyticsScreenName.ScreenHome;
    case Routes.Colleges:
      return AnalyticsScreenName.ScreenColleges;
    case Routes.Essays:
      return AnalyticsScreenName.ScreenEssays;
    case Routes.Activities:
      return AnalyticsScreenName.ScreenActivities;
    case Routes.Profile:
      return AnalyticsScreenName.ScreenProfile;
    case Routes.Resources:
      return AnalyticsScreenName.ScreenResources;
    case Routes.GeneratePlan:
      return AnalyticsScreenName.ScreenGeneratePlan;
    case Routes.Plan:
      return AnalyticsScreenName.ScreenPlanner;
    case Routes.Verify:
      return AnalyticsScreenName.ScreenVerify;
    case Routes.Confirm:
      return AnalyticsScreenName.ScreenConfirm;
    case Routes.Join:
      return AnalyticsScreenName.ScreenJoin;
    case Routes.Forgot:
      return AnalyticsScreenName.ScreenForgot;
    case Routes.Reset:
      return AnalyticsScreenName.ScreenReset;
    default:
      return undefined;
  }
};

export enum ALERT_TYPES {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

export enum SUGGESTED_TOPIC_OPTIONS_BRAINSTORMER {
  CoolIdea = "Cool idea, let's go with it.",
  DislikeIdea = 'Can I see another similar topic?',
  NotSure = 'I’m not sure, lets keep talking.',
}
export enum SUGGESTED_TOPIC_OPTIONS_BRAINSTORMER_TOOLTIP {
  CoolIdeaToolTip = 'Generate a plan based on this topic and your background',
  DislikeIdeaToolTip = 'See another similar topic based on your AI chat',
  NotSureToolTip = 'Restart chat to come up with a different topic',
}

export const NoneOfTheAboveTopicSuggestionText = '🙅 None of the above';

export const APPLICANT_BACKGROUND_MAX_WORD_COUNT = 150;

export enum COLLEGE_DEADLINE_TYPES {
  RESTRICTIVE_EARLY_ACTION = 'Restrictive Early Action',
  EARLY_ACTION = 'Early Action',
  EARLY_DECISION = 'Early Decision',
  EARLY_DECISON_II = 'Early Decision II',
  REGULAR_DECISON = 'Regular Decision',
}

export const DEADLINE_LABELS = {
  RD: 'Regular Decision',
  TRANSFER: 'Transfer Deadline',
  REA: 'Restrictive Early Action',
  ED: 'Early Deadline',
  ED2: 'Early Decision II',
  EA: 'Early Action',
  EA2: 'Early Action II',
  rolling: 'Rolling Deadline',
};

export enum COLLEGE_FILTER_OPTIONS_MAX_LIMIT {
  TuitionFee = 100000,
  RecommendationLetters = 5,
  RequiredSupplements = 15,
  InstateTuition = 27600,
  UndergraduateEnrollment = 65870,
  AverageCostAfterAid = 60000,
  ApplicationFee = 150,
}

export enum COLLEGE_FILTER_OPTIONS_DEFAULT_VALUE {
  TuitionFee = 100000,
  RecommendationLetters = 5,
  RequiredSupplements = 11,
  InstateTuition = 27600,
  UndergraduateEnrollment = 65870,
  AverageCostAfterAid = 60000,
  ApplicationFee = 150,
}

export const RADAR_CHART_OPTIONS = {
  WIDTH: '90%',
  HEIGHT: '90%',
  TICK_COLOR: '#035A41',
  RADAR_STROKE: '#3e8a75',
  RADAR_FILL: '#3E8A75',
  FILL_OPACITY: 0.2,
  TICK_FONT_SIZE: 14,
  TICK_FONT_WEIGHT: 200,
  GRID_STROKE: '#F9FAFB',
};

export const GRADE_COLORS = {
  9: '#0B8E69',
  10: '#33BFFF',
  11: '#F9633D',
  12: '#FFCB33',
};

export const GRADE_COLORS_LIST = {
  9: { bg: 'grade9', color: 'calendarC8' },
  10: { bg: 'grade10', color: 'activitySTEM' },
  11: { bg: 'grade11', color: 'calendarC3' },
  12: { bg: 'grade12', color: '#FFA800' },
};

export const DEFAULT_HIGHLIGHT_COLOR = 'white';

export const HIGHLIGHTER_TOOL_COLORS = [
  // Gray
  '#808080',
  '#A9A9A9',
  '#D3D3D3',
  '#E8E8E8',
  '#F5F5F5',
  // Red
  '#FF0000',
  '#FF6347',
  '#DC143C',
  '#FFC0CB',
  '#FFD6D6',
  // Green
  '#008000',
  '#32CD32',
  '#00FF00',
  '#ADFF2F',
  '#E6FFE6',
  // Blue
  '#0000FF',
  '#4682B4',
  '#87CEEB',
  '#B0E0E6',
  '#E0F5FF',
  // Yellow
  '#FFFF00',
  '#FFD700',
  '#FFEA00',
  '#FFFACD',
  '#FFFFE0',
  // Orange
  '#FFA500',
  '#FF7F50',
  '#FF4500',
  '#FFDAB9',
  '#FFE5CC',
  // Purple
  '#800080',
  '#8A2BE2',
  '#BA55D3',
  '#DDA0DD',
  '#E6E6FA',
  // Pink
  '#FFC0CB',
  '#FF69B4',
  '#FF1493',
  '#FFB6C1',
  '#FFD9EC',
];
export const QUESTIONNAIRE_SUBJECTS_DEFAULT = [
  ['English', 'Chemistry', 'Engineering', 'Economics'],
  ['Sociology', 'Biology', 'History', 'Computer Science'],
  ['Communication', 'Maths', 'Physics', 'Undecided'],
];

export const QUESTIONNAIRE_SUBJECTS_SMALL_SCREEN = [
  ['English', 'Chemistry', 'Engineering'],
  ['Maths', 'Biology', 'Computer Science'],
  ['Communication', 'History', 'Physics'],
  ['Sociology', 'Economics', 'Undecided'],
];

export const SCHOOL_OF_YEAR = ['9', '10', '11', '12', 'Post Graduation', 'Continued'];
export const SCHOOL_YEAR = ['9', '10', '11', '12', 'pg', 'continued'];
