import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

interface StyledCardProps extends BoxProps {
  children: React.ReactNode;
  width?: string | number;
  height?: string | number;
}

const StyledCard: React.FC<StyledCardProps> = ({ children, width = '100%', height = 'auto', ...rest }) => (
  <Box
    width={width}
    height={height}
    borderRadius='12px'
    border='1px solid'
    borderColor='#CDD8DF'
    background='foreground'
    padding={4}
    {...rest}
  >
    {children}
  </Box>
);

export default StyledCard;
