import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { TypeIcon } from '../../../Assets/icons';

interface IInfoItem {
  heading?: string;
  Icon?: React.FC;
  subheading?: string;
}

const InfoItem: React.FC<IInfoItem> = ({ heading = '', Icon = TypeIcon, subheading = '' }) => (
  <HStack gap={3} align={'center'} justifyItems={'center'}>
    <Box backgroundColor={'#ECF1F4'} borderRadius={'full'} padding={3}>
      <Icon width={'20px'} height={'20px'} color={'#4A4A68'} />
    </Box>
    <VStack align={'start'} gap={0}>
      <Text fontSize={'12px'} fontWeight={400} color={'secondaryBlue'}>
        {heading}
      </Text>
      <Text fontSize={['12px', '16px']} fontWeight={500} color={'black1'} lineHeight={'150%'}>
        {subheading}
      </Text>
    </VStack>
  </HStack>
);

export default InfoItem;
