import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  Button,
  Divider,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useAtom, useAtomValue } from 'jotai';
import React, { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserIcon } from '../../../Assets/icons';
import { userAtom, userFirstNameAtom } from '../../../Atoms/App/user';
import { demoAtom } from '../../../Atoms/demoAtom';
import { notesAtom } from '../../../Atoms/notesAtom';
import { AnalyticsScreenName, Routes } from '../../../Constants/constants';
import { SHOW_DEMO_PAGES } from '../../../Constants/demo';
import useAuth from '../../../Hooks/Auth/useAuth';
import { mapPathToRoute } from '../../../Utils/app';
import useMixpanel from '../../../lib/Analytics/Mixpanel/useMixpanel';
import ProfileAvatar from '../../../lib/UI-Components/ProfileAvatar';
import ReportModal from '../../Report/ReportModal';
import { getDemoSteps } from '../../TourSteps';

const ProfileAndAddonsSection = ({ isUserOnPublicRoute, isPrivateRoute }: any) => {
  const user = useAtomValue(userAtom);
  const userFirstName = useAtomValue(userFirstNameAtom);
  const [, setDemo] = useAtom(demoAtom);
  const [notes, setNotes] = useAtom(notesAtom);
  const [isHovered, setIsHovered] = useState(false);

  const { trackEvent } = useMixpanel();
  const navigate = useNavigate();
  const { logOut } = useAuth();
  const { isOpen: isReportOpen, onOpen: onReportOpen, onClose: onReportClose } = useDisclosure();

  const userLocation = useLocation();

  function handleViewDemo() {
    const demoSteps = getDemoSteps(userFirstName || '');
    const demoInfo = demoSteps[userLocation.pathname];

    setDemo((prev) => ({
      ...prev,
      showJoyride: true,
      manualTrigger: true,
      screen: userLocation.pathname,
      steps: demoInfo?.steps || [],
      isControlled: !demoInfo?.continuous,
    }));
  }

  function handleTakeNotes() {
    setNotes({
      ...notes,
      isOpen: !notes?.isOpen,
      isCreateNoteView: false,
      isEditNoteView: false,
    });
  }

  const menuItems = useMemo(() => {
    const currentRoute = mapPathToRoute(userLocation.pathname);
    const shouldShowDemo = SHOW_DEMO_PAGES.includes(currentRoute);

    return [
      { label: 'Profile', route: Routes.Profile, event: 'Open Student Profile', isVisible: true },
      { label: 'View demo', onClick: handleViewDemo, event: 'View Demo', isVisible: shouldShowDemo },
      {
        label: 'Report an issue',
        onClick: onReportOpen,
        event: 'Report Problem',
        isVisible: SHOW_DEMO_PAGES.includes(currentRoute),
      },
      { label: 'Take notes', onClick: handleTakeNotes, event: 'Open Notes', isVisible: true },
      { label: 'Kollegio guide', route: Routes.Resources, event: 'Open Resources', isVisible: true },
      { label: 'Log out', onClick: logOut, event: 'Log Out', isVisible: true },
    ];
  }, [userLocation.pathname, handleViewDemo, onReportOpen, handleTakeNotes, logOut]);

  const renderMenuItems = useCallback(
    () =>
      menuItems
        .filter(({ isVisible }) => isVisible) // Only render visible items
        .map(({ label, route, onClick, event }) => (
          <MenuItem
            key={label}
            fontSize='14px'
            color='gray17'
            fontWeight='medium'
            p={2}
            px={2}
            onClick={async () => {
              if (event) trackEvent(event, { screenName: AnalyticsScreenName.ScreenHeader, label });
              if (route) navigate(route);
              if (onClick) onClick();
            }}
          >
            {label}
          </MenuItem>
        )),
    [menuItems, navigate]
  );

  if (isUserOnPublicRoute || !isPrivateRoute) return null;

  return (
    <HStack justifyContent='right' alignItems='center' h='100%'>
      <ReportModal isOpen={isReportOpen} onClose={onReportClose} />
      <HStack justifyContent='right' spacing={0} alignItems='center'>
        <Flex align='end' gap={[1, 1, 3, 5]}>
          {/* Notification icon placeholder */}
        </Flex>
        <Menu>
          <MenuButton
            as={Button}
            bg='foreground'
            pr={1}
            pl={1}
            borderRadius='16px'
            _hover={{ cursor: 'pointer', backgroundColor: 'green7' }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <HStack spacing={3}>
              <ProfileAvatar boxSize={8} currentColor={isHovered ? 'white' : 'gray17'} />
              <Text color='black1' fontSize='16px' fontWeight='500'>
                {userFirstName}
              </Text>
              <ChevronLeftIcon boxSize={5} transform='rotate(-90deg)' color='gray17' />
            </HStack>
          </MenuButton>
          <MenuList zIndex='100' px={2} width='280px' borderRadius='8px' pt={3}>
            <MenuGroup pb={2}>
              <Flex direction='column' align='center' gap={2}>
                <Flex rounded='full' bg='green7' align='center' justify='center' color='foreground' w='56px' h='56px'>
                  <UserIcon color='contrast' w='25px' h='25px' />
                </Flex>
                <Text color='black1' fontWeight='semibold' fontSize='20px'>
                  Hi, {userFirstName}!
                </Text>
                <Text color='territary' fontSize='12px'>
                  {user?.email}
                </Text>
              </Flex>
            </MenuGroup>
            <Divider my={3} borderColor='gray12' />
            <MenuGroup>{renderMenuItems()}</MenuGroup>
          </MenuList>
        </Menu>
      </HStack>
    </HStack>
  );
};

export default ProfileAndAddonsSection;
